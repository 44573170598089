$site-header-mobile__bg: $cl_White !default;

$site-header-mobile__height: 56px !default;

$site-header-mobile__btn-space-around: 8px !default;
$site-header-mobile__btn-font-size: 22px !default;
$site-header-mobile__btn-color: $brand-primary-alternative !default;

%site-header-mobile-btn {
    padding: $site-header-mobile__btn-space-around;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background: none;
    font-size: $site-header-mobile__btn-font-size;
    text-align: center;
    color: $site-header-mobile__btn-color;
    &:focus {
        outline: 0;
    }
}


.site-header {

    $site-header: &;

    &-mobile {

        min-width: 320px; // same as for #root (unilayer/layout/_base.scss) because of "position: absolute"
        background-color: $site-header-mobile__bg;

        &__inner-container {
            height: $site-header-mobile__height;
        }

        /* middle parts */
        &__left-pane,
        &__right-pane {
            flex: 0 0 88px;
        }

        &__left-pane {
            // buttons/links have space around the icon, so compensate it
            margin-left: -$site-header-mobile__btn-space-around;
        }

        &__middle-pane {
            flex: 1 1 auto;
            margin-left: 1rem; // some space between blocks
            margin-right: 1rem; // some space between blocks
        }

        &__right-pane {
            // buttons/links have space around the icon, so compensate it
            margin-right: -$site-header-mobile__btn-space-around;
        }


        /* elements */
        &__logo {
            &-img {
                display: block;
                max-width: 100%; // in case you have forgot to add .img-responsive
                max-height: 33px; // calculated special for iPhone6/7/8 (for 375px screen width)
            }
        }

        &__toggle-underheader-btn {
            @extend %site-header-mobile-btn;
            &_menu {
                margin-right: 6px;
                &::before {
                    @extend %un-bars-slim;
                }
            }
            &_search {
                &::before {
                    @extend %un-search-slim;
                }
            }
        }

    }

}