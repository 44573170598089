.site-header-mobile {

    .SiteCartPanel {
        display: flex;
        align-self: stretch;
        .SiteCartIcon {
            @extend %site-header-mobile-btn;
        }
    }

}