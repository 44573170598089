.SearchDesktop {
    &__input {
        background-color: transparent;
        border-radius: 2.8rem;
    }
}
.site-header-desktop__middle-line{
    background-color: $brand-primary;
}
.site-horizontal-menu {
    .Level-1 {
        > li {
            > .Name {
                font-weight: 500;
                text-transform: uppercase;
                color: $cl_White;
                &::before {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    right: 100%;
                    content: "";
                    display: block;
                    height: 1px;
                    margin-top: -7px;
                    background-color: $cl_White;
                    transition: right $animation-duration;
                }
            }
            &:hover,
            &.Selected {
                > .Name {
                    color: $cl_White;
                    &::before {
                        right: 0;
                    }
                }
            }
        }
    }
}