@mixin article-teaser{
    font-size: 1.6rem;
    line-height: 2.4rem;
}

@mixin article-content{
    line-height: 2.2rem;
}

@mixin article-formating{
    > p,
    > ol,
    > ul {
        margin: 0 0 2.5rem;
    }
    :last-child {
        margin-bottom: 0;
    }
    a{
        text-decoration: underline;
    }
}

@mixin u-text-inherit{
    margin-top: 0;
    margin-bottom: 0;
    line-height: inherit;
    font-size: inherit;
    font-weight: inherit;
}

@mixin u-transition-colors{
    transition: border-color $animation-duration, background-color $animation-duration, color $animation-duration;
}

%u-transition-colors{
    @include u-transition-colors;
}


/// $font: <font-size> <font-weight> <font-style>;
@mixin font-properties ($font) {
    font-size: nth($font, 1);
    @if length($font) > 1 {
        font-weight: nth($font, 2);
    }
    @if length($font) > 2 {
        font-style: nth($font, 3);
    }
}