[data-toggle="collapse"]{
    cursor: pointer;
}
[type="button"]{
    .fa{
        pointer-events: none;
    }
}
label[for]{
    cursor: pointer;
}

p:last-child{
    margin-bottom: 0;
}

dt,
label{
    font-weight: $label-font-weight;
}

.Image {
    @extend .img-responsive;
}
//Add animation to icons used within panels
.panel-heading[data-toggle="collapse"]{
    &[aria-expanded="false"]{
        i{
            transform: rotate(0);
        }
    }
    i{
        float: right;
        transform: rotate(-180deg);
        transition: transform $animation-duration $animation-timing-function;
    }
}