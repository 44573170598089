.site-header-desktop {

    .SiteCartPanel {
        display: flex;
        align-self: center;
        margin-left: 9px;
        .SiteCartIcon {
            @extend %site-header-desktop-btn;
        }
        .PopupContainer{
            .page-checkout &{
                display: none;
            }
        }
    }

}