footer {
  background-color: $brand-primary;
  font-size: 1.8rem;
  line-height: 1.6666666667;
  color: $cl_White;

    h6 {
      margin-top: 0;
      margin-bottom: 13px;
      font-size: 1.8rem;
      line-height: auto;
    }

    .description {
      
      font-size: 1.8rem;

      p {
        margin: 0;
        line-height: 1.6666666667;
      }

      a {
        color: $cl_White;
      }

      b {
        font-weight: 600;
      }
    }
}


.mt-g8 {
    margin-top: 6.4rem;
}

.UC110MainTitle {
  position: relative;
  margin-bottom: 4.4rem;

  &Container {
    text-align: center;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -27px;
    width: 48px;
    height: 6px;
    border-radius: 3px;
    background-color: $dodger-blue;
    transition: width 150ms ease-out;
    left: 50%;
    transform: translateX(-50%);
  }
}



#bottomNav {
    padding: 2.1rem 0;

    .navbar-nav {
      flex-wrap: wrap;
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
      flex-direction: row;
      display: flex;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
      float: none;
    }

    .nav-item {
      float: none;
      position: relative;
      padding: 0 2.4rem;
      
      @media (min-width: 768px) {
        &:not(:first-child)::before {
            display: inline-block;
            content: "";
            border-radius: 3px;
            background-color: #2699fb;
            position: absolute;
            left: 0;
            width: 3px;
            height: 22px;
            top: calc(50% - 11px);
        }
      }
    }

    a {
        font-weight: 700;
        font-size: 1.6rem;
        padding-top: 0.32rem;
        padding-bottom: 0.32rem;
        color: $cl_White;


        @media (min-width: 576px) {
          padding-right: 0.8rem;
          padding-left: 0.8rem;
        }
    }

    &.bg-dark {
      background-color: #1a1a1a !important;
    }

}
