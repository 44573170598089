////
/// @group un
////

@font-face {
    font-family: "uniicons";
    src: url("../fonts/uniicons.woff2?v=1621411435140") format("woff2"),
         url("../fonts/uniicons.woff?v=1621411435140") format("woff"),
         url("../fonts/uniicons.ttf?v=1621411435140") format("truetype"),
         url("../fonts/uniicons.svg?v=1621411435140#uniicons") format("svg");
    font-weight: normal;
    font-style: normal;
}

/// uniicon (icons font) default styles
%un {
    font-family: "uniicons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.un {
    @extend %un;
}

/// @deprecated deprecated since unilayer 7.4.0
/// @group deprecated
.uniicons {
    @extend %un;
}
%uniicons {
    @extend %un;
}


/// check icon
/// Placeholder contains only 'content' property, so use it only for ::before and ::after pseudoelements
/// @example scss
///    .ElementWhereYouWantToPlaceIcon {
///        &::before {
///            @extend %un-check;
///        }
///    }
%un-check {
    @extend %un;
    content: "\E902";
}
.un-check::before {
    @extend %un-check;
}


/// key icon
/// Placeholder contains only 'content' property, so use it only for ::before and ::after pseudoelements
/// @example scss
///    .ElementWhereYouWantToPlaceIcon {
///        &::before {
///            @extend %un-key;
///        }
///    }
%un-key {
    @extend %un;
    content: "\E90A";
}
.un-key::before {
    @extend %un-key;
}


/// scrollbar-horizontal icon
/// Placeholder contains only 'content' property, so use it only for ::before and ::after pseudoelements
/// @example scss
///    .ElementWhereYouWantToPlaceIcon {
///        &::before {
///            @extend %un-scrollbar-horizontal;
///        }
///    }
%un-scrollbar-horizontal {
    @extend %un;
    content: "\E91D";
}
.un-scrollbar-horizontal::before {
    @extend %un-scrollbar-horizontal;
}


/// site-cart3-plus icon
/// Placeholder contains only 'content' property, so use it only for ::before and ::after pseudoelements
/// @example scss
///    .ElementWhereYouWantToPlaceIcon {
///        &::before {
///            @extend %un-site-cart3-plus;
///        }
///    }
%un-site-cart3-plus {
    @extend %un;
    content: "\E921";
}
.un-site-cart3-plus::before {
    @extend %un-site-cart3-plus;
}


/// site-cart3 icon
/// Placeholder contains only 'content' property, so use it only for ::before and ::after pseudoelements
/// @example scss
///    .ElementWhereYouWantToPlaceIcon {
///        &::before {
///            @extend %un-site-cart3;
///        }
///    }
%un-site-cart3 {
    @extend %un;
    content: "\E922";
}
.un-site-cart3::before {
    @extend %un-site-cart3;
}


/// site-cart3-empty icon
/// Placeholder contains only 'content' property, so use it only for ::before and ::after pseudoelements
/// @example scss
///    .ElementWhereYouWantToPlaceIcon {
///        &::before {
///            @extend %un-site-cart3-empty;
///        }
///    }
%un-site-cart3-empty {
    @extend %un;
    content: "\E923";
}
.un-site-cart3-empty::before {
    @extend %un-site-cart3-empty;
}


/// close2 icon
/// Placeholder contains only 'content' property, so use it only for ::before and ::after pseudoelements
/// @example scss
///    .ElementWhereYouWantToPlaceIcon {
///        &::before {
///            @extend %un-close2;
///        }
///    }
%un-close2 {
    @extend %un;
    content: "\E924";
}
.un-close2::before {
    @extend %un-close2;
}


/// user-slim icon
/// Placeholder contains only 'content' property, so use it only for ::before and ::after pseudoelements
/// @example scss
///    .ElementWhereYouWantToPlaceIcon {
///        &::before {
///            @extend %un-user-slim;
///        }
///    }
%un-user-slim {
    @extend %un;
    content: "\E932";
}
.un-user-slim::before {
    @extend %un-user-slim;
}


/// hand-bag-slim icon
/// Placeholder contains only 'content' property, so use it only for ::before and ::after pseudoelements
/// @example scss
///    .ElementWhereYouWantToPlaceIcon {
///        &::before {
///            @extend %un-hand-bag-slim;
///        }
///    }
%un-hand-bag-slim {
    @extend %un;
    content: "\E933";
}
.un-hand-bag-slim::before {
    @extend %un-hand-bag-slim;
}


/// search-slim icon
/// Placeholder contains only 'content' property, so use it only for ::before and ::after pseudoelements
/// @example scss
///    .ElementWhereYouWantToPlaceIcon {
///        &::before {
///            @extend %un-search-slim;
///        }
///    }
%un-search-slim {
    @extend %un;
    content: "\E934";
}
.un-search-slim::before {
    @extend %un-search-slim;
}


/// bars-slim icon
/// Placeholder contains only 'content' property, so use it only for ::before and ::after pseudoelements
/// @example scss
///    .ElementWhereYouWantToPlaceIcon {
///        &::before {
///            @extend %un-bars-slim;
///        }
///    }
%un-bars-slim {
    @extend %un;
    content: "\E936";
}
.un-bars-slim::before {
    @extend %un-bars-slim;
}


/// file-list icon
/// Placeholder contains only 'content' property, so use it only for ::before and ::after pseudoelements
/// @example scss
///    .ElementWhereYouWantToPlaceIcon {
///        &::before {
///            @extend %un-file-list;
///        }
///    }
%un-file-list {
    @extend %un;
    content: "\E937";
}
.un-file-list::before {
    @extend %un-file-list;
}

