.umw-bonus-status{
    &__heading{
        margin-top: 0;
    }
    &__container{
        @extend %card-default-style;
        @extend %card-padding-base;
    }
    &__heading-term-description{
        font-weight: normal;
        margin-left: 8px;
    }
    &__heading-term-description {
        font-size: $font-size-base;
    }

    &__balance-term-description,
    &__cart-profit-term-description{
        font-weight: $label-font-weight;
        margin-left: $grid-size * 0.5;
    }
    .umw-bonus-balance{
        margin-bottom: $grid-size * 2;
    }
    &__cart-profit{
        margin-bottom: 10px;
    }
    &__page-link{
        text-decoration: underline;
    }
}