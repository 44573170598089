.site-menu-mobile{
    a{
        font-size: 13px;
        display: block;
        padding: $grid-size 0;
    }
    .Name{
        color: $text-color;
    }
    .Toggle{
        display: none;
    }
}