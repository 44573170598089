.uc211{
    max-width: 968px;
    table{
        td{
            vertical-align: inherit !important;
        }
    }
    &__heading{
        @extend %u-heading-h2;
        &-term-description{
            margin-left: $grid-size;
            @extend %u-heading-h3;
        }
    }
    &__expiration{
        &-alert{
            background-color: $alert-danger-bg;
            padding: $grid-size * 2;
            max-width: 400px;
            table {
                background-color: rgba(255, 255, 255, .8);
                margin: 0;
                thead{
                    th{
                        background-color: #fff;
                    }
                }
            }
        }
        &-details {
            &-table-container {
                padding: $grid-size * 2 $grid-size;
            }
        }
        &-notification{
            text-align: center;
        }
        &-details-link-container{
            text-align: center;
            margin-top: $grid-size;
            a{
                text-decoration: underline;
                color: $text-color;
                font-weight: $label-font-weight;
            }
        }
    }
    &__active-bonuses-status{
        @extend %bonus-system-before-flag;
        text-align: center;
        background-color: $cl_Mystic;
        padding: $grid-size;
        margin-top: 0;
    }
    &__pending-bonuses-status{
        text-align: center;
        font-weight: $label-font-weight;
        margin-top: $grid-size * 2;
        color: #818A91;
    }
    &__bonus-history{
        margin-top: $grid-size * 2;
        @extend %card-default-style;
        @extend %card-padding-base;
        &-table{
            @media (max-width: $screen-xs-max) {
                @include make-table-mobile();
                tbody>tr{
                    border-top-width: 10px;
                    &:not(:first-child){
                        margin-top: 15px;
                    }
                }
                .is-empty{
                    display: none;
                }
            }
        }
    }
    &__bonus-operation{
        padding: $grid-size;
        position: relative;
        &_minus{
            background-color: $alert-danger-bg;
        }
        &_plus{
            background-color: $alert-success-bg;
        }
        &-info-btn{
            line-height: 0;
            position: absolute;
            top: 6px;
            right: 6px;
            @extend %u-reset-btn-to-a-block;
            color: $text-color-gray;
            &::before {
                @extend %fa-info-circle;
            }
        }
    }
    &__bonus-status{
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #818A91;
        &_spent{
            &::after {
                @extend %fa-check;
            }
        }
        &_earned,
        &_earn-activated{
            color: $text-color-gray;
            &::after {
                color: $alert-success-text;
                @extend %fa-check;
            }
        }
        &_pending{
            &::after {
                @extend %fa-ellipsis-h;
            }
        }
        &_canceled,
        &_earn-expired{
            &::after {
                @extend %fa-close;
            }
        }
    }
    &__bonus-order-number{
        text-decoration: underline;
        color: $text-color;
    }
    &__bonus-history-link-container{
        margin-top: $grid-size * 2;
        text-align: center;
    }
    &__show-all-bonus-history{
        text-decoration: underline;
        color: $text-color;
        font-weight: $label-font-weight;
    }
    &__bonus-program-redirect {
        @extend %u-btn-size-large;
        margin-top: $grid-size * 3;
    }
}