.uc205{
    $uc205__table-color: $cl_Quill_Gray;
    $uc205__avatar-width: 90px !default;
    $uc205__avatar-height: 90px !default;
    $uc205__gutter: 18px !default;

    &-default{
        &__section{
            @extend %card-default-style;
        }
        &__btn-type_1{
            font-size: 1.4em;
            color: $gray-light;
            &:hover,
            &:focus{
                text-decoration: none;
            }
        }
        &__user-data{
            float: left;
        }
        &__header-buttons{
            float: right;
        }

        &__review {
            margin-bottom: 2em;
            padding: $uc205__gutter $grid-size * 4;
            &:not(:first-child){
                border-top: 1px solid $uc205__table-color;
            }
            &-header{
                margin-bottom: $uc205__gutter;
                &:after{
                    content: "";
                    display: table;
                    width: 100%;
                }
            }
            &:after{
                content: "";
                display: table;
                width: 100%;
            }
            &_pinned{
                border-color: $cl_Tundora;
            }
        }

        &__avatar {
            float: left;
            line-height: $uc205__avatar-height;
            width: $uc205__avatar-width;
            height: $uc205__avatar-height;
            margin-right: 12px;
            border: 1px solid $uc205__table-color;
            border-radius: 50%;
            overflow: hidden;
            font-size: 4em;
            text-align: center;
            color: $uc205__table-color;

            // &-img {
            //     width: $uc205__avatar-width;
            //     height: $uc205__avatar-height;
            // }
        }
        &__author {
            display: inline-block;
            margin-top: 20px;
            font-weight: 700;
        }
        &__datetime:before {
            content: " - ";
        }
        .c-rating{
            margin-top: 9px;
            font-size: 1.6em;
        }

        &__review-text{

        }
        &__message{
            &-form{
                &:after{
                    content: "";
                    display: table;
                    width: 100%;
                }
                &_reply{
                    margin-top: $uc205__gutter;
                }
            }
            &-input{
                min-height: 100px;
                line-height: 24px;
                margin-bottom: 10px;
                &::placeholder{
                    font-style: italic;
                    color: $cl_Mountain_Mist2;
                }
                &_reply{
                    &::placeholder{
                        font-weight: 600;
                        color: $cl_Tundora;
                    }
                }
                &_edit{
                    min-height: 400px;
                }
            }
            &-submit{
                float: right;
                padding-left: 24px;
                padding-right: 24px;
                font-weight: 500;
            }
        }

        &__footer-buttons{
            text-align: right;
            margin-top: 10px;
        }

        &__reply-text{
            margin-top: $uc205__gutter;
            padding: $uc205__gutter;
            border: 1px solid $uc205__table-color;
            border-radius: $border-radius-large;
            background-color: #F7F7F7;
            font-style: italic;
            font-weight: 300;
        }

        &__edit{
            &::before{
                @extend %fa-pencil;
            }
        }
        &__like{
            &.uc205-voted{
                color: $brand-primary;
            }
            &::before{
                @extend %fa-thumbs-up;
            }
        }
        &__likes-count{
            font-size: $font-size-small;
            margin-left: 7px;
        }
        &__reply{
            &::before{
                @extend  %fa-comment;
            }
        }
        &__remove{
            &:hover{
                color: $brand-danger;
            }
            &::before{
                @extend %fa-trash;
            }
        }
        &__more-reviews {
            @extend %u-btn-size-large;
            min-width: 230px;
            &-btn-container{
                border-top: 1px solid $uc205__table-color;
                padding: $grid-size * 3 0;
                display: flex;
                justify-content: center;
            }
            &-count{
                display: none;
                margin-left: $grid-size;
            }
        }
    }
}