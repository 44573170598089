/*======================================
=            Shopping Lists            =
======================================*/
@import "uc201-common";
.uc201{
    @import "uc201-xx-common";

    /*----------  SHOPPING LIST DETAILS VIEW  ----------*/
    &-shopping-list-details{
        &__replaced-product-alert{
            margin-top: 10px;
            margin-bottom: 0;
        }
        &__quantity-input-group{
            max-width: 140px;
            min-width: 100px;
        }
        &__quantity-input{
            padding-top: 0;
            padding-bottom: 0;
            height: $u-buy-btn-height-base;
        }
    }
}
/*=====  End of Shopping Lists  ======*/