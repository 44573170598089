/*----------  Page modificators  ----------*/
.hide-breadcrumbs {
    .Breadcrumbs {
        display: none;
    }
}
.hide-step-indicator {
    .c-checkout-step-indicator {
        display: none !important;
    }
}
.hide-flag {
    .flag {
        display: none;
    }
}
.page_drop-content-top-padding{
    .site-content__page-content{
        padding-top: 0 !important;
    }
}
.page_drop-content-bottom-padding{
    .site-content__page-content{
        padding-bottom: 0 !important;
    }
}