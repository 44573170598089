.umw-department{
    &__item{
        position: relative;
    }
    label {
        border: 2px solid $table-border-color;
        border-left-width: 20px;
        padding: 10px 10px 10px 16px;
        display: block;
        border-radius: $border-radius-base;
    }
    input:checked + label {
        border-color: $brand-primary;
    }
    &__selector{
        display: none;
    }
    &__details{
        font-weight: 400;
    }
    &__name{
        font-weight: 600;
        margin-bottom: $grid-size;
    }
    &__info-link-container{
        margin-top: $grid-size;
    }
}