// &-shopping-lists
// &-shopping-list-details
// &-shopping-list _shared - title is not approved
.uc201{
    & > section{
        margin-bottom: $grid-size * 2;

        h2 {
            @extend %u-heading-h1;
        }
    }
    .js-keyboard-nav-cursor {
        outline: 1px dotted $brand-info;
    }
    .js-keyboard-nav-cursor {
        outline: 1px dotted $brand-info;
    }

    /*----------  SHOPPING LISTS GRID VIEW  ----------*/
    .grid{
        background-color: $cl_White;
        margin-bottom: 20px;

        @media (min-width: $screen-sm-min) {
            @include make-grid ($table-cell-padding $padding-large-horizontal);
            @include grid-bordered();
        }

        @media (max-width: $screen-xs-max){
            @include make-mobile-grid (1px solid $cl_Cararra, $padding-large-vertical, $padding-small-vertical 0);
        }

    }

    .form-group-lg {
        margin-bottom: 4rem;
    }


}