/*======================================
=            ORDER HISTORY             =
======================================*/
.uc202 {
    //&-order-history
    &-order-details{
        display: flex;
        flex-direction: column;
    }

    /*----------  Order history search view  ----------*/
    &-order-history__tabs{
        margin-bottom: $form-group-margin-bottom * 1.5;
    }

    &-order-history__search,
    &-order-history__quick-search,
    &-order-history__pager-container_top,
    &-order-history__grid{
        margin-bottom: $form-group-margin-bottom * 2.6;
    }
    &-order-history__search-inputs{
        display: flex;
        flex-direction: column;

        @media (min-width: $screen-sm-min) {
            flex-direction: row;
        }

        input[type="text"],
        button{
            margin: 0 0 1rem 0;

            @media (min-width: $screen-sm-min) {
                margin: 0 7.5px;
            }
        }

        button{
            width: 100%;
        }

        @media (min-width: $screen-md-min){
            input[type="text"]{

            }
        }
    }
    &-order-history__quick-search-select {
        @media (min-width: $screen-sm-min){
            max-width: 200px;
        }
    }
    &-delivery-status{
        color: $cl_Gray;
        @media (min-width: $screen-sm-min){
            text-align: center;
        }
        // &_10{ //Registered
        // }
        // &_20{ //Packaging
        // }
        // &_30{ //Part-sent
        // }
        // &_40{ //Sent
        // }
        // &_50{ //Received
        // }

        &__label{
            margin-left: $padding-small-horizontal;
            @media (min-width: $screen-sm-min){
                display: none;
            }
        }
    }

    &-order-history__grid,
    &-order-details__order-lines-grid,
    &-order-details__packages-grid {
        background-color: $cl_White;
        @media (min-width: $screen-sm-min){
            @include make-grid ($table-cell-padding $padding-large-horizontal);
            @include grid-bordered (1px solid $cl_Cararra);
        }
        @media (max-width: $screen-xs-max){
            @include make-mobile-grid (1px solid $cl_Cararra, $padding-large-vertical, $padding-small-vertical $padding-large-horizontal);
        }
    }

    &-order-history__grid{
        .grid-body{
            .grid-cell{
                cursor: pointer;
            }
        }
    }



    /*-------  My bought products view  --------*/

    &-my-bought-products{
        &__grid{
            background-color: $cl_White;
            @media (min-width: $screen-sm-min){
                @include make-grid ($table-condensed-cell-padding $padding-small-horizontal);
                @include grid-bordered (1px solid $cl_Cararra);
            }
            @media (max-width: $screen-xs-max){
                @include make-mobile-grid (1px solid $cl_Cararra, $padding-large-vertical, $table-condensed-cell-padding $padding-small-horizontal);
            }
            .grid-header{
                font-size: ceil(($font-size-base * 1.1)); // ~16px
            }
        }
        &__quantity-input-group{
            width: 100px;
        }
    }



    /*----------  Order details view  ----------*/

    &-order-details__created-by-info,
    &-order-details__first-line,
    &-order-details__shipping-info,
    &-order-details__order-lines,
    &-order-details__order-lines-summary{
        margin-bottom: $grid-size * 3;
    }
    &-order-details__created-by-info{
        display: block;
    }
    &-order-details__first-line{
        display: flex;
        flex-direction: column;
        @media (min-width: $screen-md-min){
            background-color: $cl_White;
            flex-direction: row;
            justify-content: space-between;
        }
        section{
            @media (min-width: $screen-md-min){
                padding: $padding-base-vertical $padding-base-horizontal;
                width: calc((100% - #{$line-height-computed}) / 2);
            }
            &:not(:first-child) {
                margin-top: $grid-size * 3;
                @media (min-width: $screen-md-min){
                    margin-top: 0;
                }
            }
        }
        .grid{ /* uc202__order-details-paymentinfo-grid, uc202__order-details-deliveryinfo-grid*/
            background-color: $cl_White;
            @include make-grid ($padding-small-vertical $padding-small-horizontal);
            @include grid-striped (0, $cl_Alabaster);
        }
    }
    &-order-details__order-lines-summary-grid{
        @include make-grid (4px 6px);
        width: auto;
    }
}
/*=====  End of ORDER HISTORY  ======*/