@mixin u-flag ($u-flag__type, $u-flag__position, $u-flag__size, $u-flag__padding, $u-flag__font) {
    text-transform: uppercase;
    @extend %u-sticker;
    @include font-properties($u-flag__font);
    @if $u-flag__type == circle {
        @include u-sticker-circle($u-flag__size);
    } @else {
        @if $u-flag__type == square {
            @include u-sticker-square($u-flag__size);
        } @else {
            @include u-sticker-rectangle($u-flag__size, $u-flag__padding);
        }
    }
    @include u-sticker-position($u-flag__position);
}