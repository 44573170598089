//Wrapping class added by scripts.js
.uc110-empty-cart{
    display: inline-block;
    line-height: 23px;
    a{
        font-weight: $label-font-weight;
        text-decoration: underline;
    }
}

.uc110-formatted{
    &-product-list-action-buttons{
        padding-top: 14px;
        padding-bottom: 14px;
        border-bottom: 1px solid $table-border-color;
    }
    &-summary-section{
        display: flex;
        flex-direction: column;
        @media(min-width: $screen-sm-min){
            justify-content: space-between;
            flex-direction: row;
        }
        &__col-1{
            display: flex;
            flex-direction: column;
            > * {
                margin-top: $grid-size * 2;
            }
        }
    }
}