$UC179_buttonsHeight: 35px !default;
$UC179_fs:            14px !default;
$UC179_chevron_fs:    10px !default;
$UC179_padding:       15px !default;

.UC179 {

    @extend  %card-default-style-sm;
    @extend  %card-padding-base-sm;

    .Level-1{
        @extend  %card-default-style-xs;
    }
    .Selected > .Name {
        font-weight: $label-font-weight;
        text-decoration: underline;
        color: $text-color;
    }

    .MenuTitleContainer{
        padding-right: 0;
        padding-left: 0;
    }
    .PagerContainer {
        margin-top: 0; //reset global styles from _paginator.scss
        padding: 0;
        font-size: $UC179_fs;
        // new
        float: none;
        clear: both;
        position: relative;
        text-align: center;
    }
    a {
        @extend %u-text-l-to-d;

        // new
        display: inline-block;
        padding: 0 5px;
        margin: 0 2px;
        cursor: pointer;
        &.Name{
            @media (min-width: $screen-sm-min) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    // new
    .PageNumber {
        min-width: 8px;
        padding: 0 5px;
        text-align: center;
    }
    span.PageNumber {
        font-weight: bold;
    }


    .PagerButtonsContainer,
    .PagerNumbersContainer,
    .PagerShowAll {
        border-top: 1px solid $table-border-color;
    }
    .PagerButtonsContainer {
        .BackButtons,
        .ForwardButtons {
            width: 50%;
            font-size: 0;
        }
        .BackButtons {
            padding-left: $UC179_padding;
            float: left;
            text-align: left;
        }
        .ForwardButtons {
            padding-right: $UC179_padding;
            float: right;
            text-align: right;
        }
        a {
            padding: 0;
            margin: 0;
            &::after,
            &::before {
                vertical-align: middle;
            }
        }
        .BackButtons a:last-child::before,
        .ForwardButtons a:first-child::after {
            content: "|";
            margin: 0 $UC179_padding;
            line-height: $UC179_buttonsHeight * 0.5;
            font-size: $UC179_fs;
            color: $cl_Cararra;
        }
    }
    .PagerNumbersContainer {
        a, span {
            line-height: $UC179_buttonsHeight;
        }
    }
    .PagerShowAll {
        a {
            color: $link-color;
            display: block;
            padding: 0;
            float: none;
            line-height: $UC179_buttonsHeight;
            text-align: center;
            text-decoration: underline;
        }
    }

}

.UC179 .BackButtons a:first-child::before,
.UC179 .BackButtons a:first-child::after,
.UC179 .BackButtons a:last-child::after {
    line-height: $UC179_buttonsHeight;
    font-size: $UC179_chevron_fs;
    @extend %fa-chevron-left;
}
.UC179 .ForwardButtons a:last-child::before,
.UC179 .ForwardButtons a:last-child::after,
.UC179 .ForwardButtons a:first-child::before {
    line-height: $UC179_buttonsHeight;
    font-size: $UC179_chevron_fs;
    @extend %fa-chevron-right;
}