/*
    $view (inline|fullwidth) - type of view
    $height - button height
    $space - space between buttons
*/
@mixin registration-type ($view: inline, $height: 36px, $space: 8px) {
    ul {
        font-size: 0;
        @extend %u-list-unstyled;
    }
    li {
        display: inline-block;
        width: calc((100% - #{$space}) / 2);
        &:not(:first-child) {
            margin-left: $space;
        }
        @if $view == inline {
            @media (min-width: $screen-sm-min) {
                width: 100px;
            }
        }
    }
    input {
        display: none;
    }
    label {
        width: 100%;
        line-height: $height;
        margin-bottom: 0;
        @extend %u-btn;
        @extend %u-btn-default;
        @extend %u-btn-size-small;
        @extend %u-btn-flex-it;
    }
    input:checked + label {
        @extend %u-btn-primary;
    }
}

%registration-type {
    @include registration-type();
}