/// Generates css to form table looking div set
/// @param {Px} $cellpadding [$table-cell-padding $padding-large-horizontal] - Defines cell padding at table grid
/// @example @include make-grid (4px 6px);
@mixin make-grid ($cellpadding: #{$table-cell-padding $padding-large-horizontal}){
    display: table;
    width: 100%;
    border-collapse: collapse;
    .grid-header {
        display: table-header-group;
    }
    .grid-body {
        display: table-row-group;
    }
    .grid-row {
        display: table-row;
    }
    .grid-cell {
        display: table-cell;
        padding: $cellpadding;
    }
}

/// @param {String} $border [null] - Border style
/// @param {Px} $rowpadding [$padding-large-horizontal] - Padding between rows
/// @param {Px} $cellpadding [$table-cell-padding] - Cell padding
/// @example @media (max-width: $screen-xs-max){
///     @include make-mobile-grid (1px solid #ebebeb, $padding-large-vertical, $padding-small-vertical $padding-large-horizontal);
/// }
@mixin make-mobile-grid ($border, $rowpadding: #{$padding-large-horizontal}, $cellpadding: #{$table-cell-padding}){
    .grid-header{
        display: none;
    }
    .grid-row{
        padding-top: $padding-large-horizontal;
        padding-bottom: $padding-large-horizontal;
        border-top: $border;
        &:first-child {
            border-top: 0;
        }
    }
    .grid-cell{
        padding: $cellpadding;
        &[data-th]{
            &:before{
                content: attr(data-th);
                margin-right: $padding-small-horizontal;
            }
        }
    }
}

/// @param {String} $border [null] - Border style
/// @param {String} $grid-row-background [null] - Grid row background color
/// @example @include grid-striped (0, $cl_Alabaster);
/// @example @include grid-striped ($cl_Black, url(img/grid-row-background.png) center no-repeat);
@mixin grid-striped ($border, $grid-row-background){
    .grid-header{
        .grid-row{
            border-top: 0;
            border-bottom: $border;
        }
    }
    .grid-body{
        .grid-row{
            &:first-child{
                border-top: 0;
            }
            &:nth-child(odd){
                background: $grid-row-background;
            }
        }
    }
    .grid-row{
        border-top: $border;
    }
    .grid-cell{
        vertical-align: middle;
    }
}

/// @param {String} $border [1px solid $table-border-color] - Border style, $table-border-color is bootstrap variable
/// @example @include grid-bordered (1px solid $cl_Black | solid black | 2px white)
/// @require {mixin} make-grid
@mixin grid-bordered ($border: #{1px solid $table-border-color}){
    border: $border;
    .grid-cell {
        vertical-align: middle;
        border: $border;
    }
}