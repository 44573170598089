.page-home {
    .UC193 {
        .MainTitleContainer {
            text-align: center;
        }
        .Item {
            &:nth-child(6) {
                &,
                & ~ .Item{
                    @media (min-width: $screen-lg-min) {
                        display: none !important;
                    }
                }
            }
        }
    }
}