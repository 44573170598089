// Mixins
// Bootstrap Button Variant
@mixin button-variant($color, $background, $border) {
  color: $color;
  border-color: $border;
  background-color: $background;
  &.focus,
  &:focus {
    color: $color;
    border-color: darken($border, 25%);
    background-color: darken($background, 10%);
  }
  &:hover {
    color: $color;
    border-color: darken($border, 12%);
    background-color: darken($background, 10%);
  }
  &.active,
  &:active,
  .open > &.dropdown-toggle {
    color: $color;
    border-color: darken($border, 12%);
    background-color: darken($background, 10%);
    &.focus,
    &:focus,
    &:hover {
      color: $color;
      border-color: darken($border, 25%);
      background-color: darken($background, 17%);
    }
  }
  &.active,
  &:active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &.focus,
    &:focus,
    &:hover {
      border-color: $border;
      background-color: $background;
    }
  }
  .badge {
    color: $background;
    background-color: $color;
  }
}
@mixin transition-all() {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}
@mixin background-cover() {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
@mixin serif-font() {
  font-family: 'Montserrat', 'Arial', sans-serif;
}
@mixin sans-serif-font() {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
@mixin after-line($bg-color: $brand-primary, $alignment: "center", $margin-bottom: 44px){
  position: relative;
  margin-bottom: $margin-bottom;
  &::after{
      content: ""; 
      display: block;
      position: absolute;
      //bottom: -0.6em;
      bottom: -27px;
      width: 48px;
      height: 6px;
      border-radius: 3px;
      background-color: $bg-color;
      transition: width 150ms ease-out;
      @if ($alignment == "left"){
          left: 0;
          transform: none;
      } @else {
          left: 50%;
          transform: translateX(-50%);
      }
  }
}
// @mixin box-bg-lighten(){
//   border: 1px solid $mystic;
//   background-color: $white-lilac;
// }
@mixin box-bg-darker(){
  border: 1px solid $mystic; // #DEE5EB
  background-color: $athens-gray !important; // #F5F6F8
}
@mixin push-xs-edges() {
  @media only screen and (max-width: 575px) {
    margin-left: -8px;
    margin-right: -8px;
    padding-left: 8px;
    padding-right: 8px;
    width: auto;
  }
}
@mixin price-large(){
  font-size: 27px;
  @include serif-font();
  font-weight: bold;
  color: $primary;
  white-space: nowrap;
  @media only screen and (min-width: 768px) {
      font-size: 36px;
  }
  @media only screen and (min-width: 992px) {
      font-size: 40px;
  }
}