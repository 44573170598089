%u-form-control {
    display: block;
    width: 100%;
    height: $input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
    padding: $padding-base-vertical $padding-base-horizontal;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $input-color;
    background-color: $input-bg;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid $input-border;
    border-radius: $input-border-radius; // Note: This has no effect on <select>s in some browsers, due to the limited stylability of <select>s in CSS.
    @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
    @include transition(border-color ease-in-out .15s, box-shadow ease-in-out .15s);

    // Customize the `:focus` state to imitate native WebKit styles.
    @include form-control-focus;

    // Placeholder
    @include placeholder;

    // Unstyle the caret on `<select>`s in IE10+.
    &::-ms-expand {
        border: 0;
        background-color: transparent;
    }

    // Disabled and read-only inputs
    //
    // HTML5 says that controls under a fieldset > legend:first-child won't be
    // disabled if the fieldset is disabled. Due to implementation difficulty, we
    // don't honor that edge case; we style them as disabled anyway.
    &[disabled],
    &[readonly]{
        background-color: $input-bg-disabled;
        opacity: 1; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655
    }

    &[disabled] {
        cursor: $cursor-disabled;
    }

    // [converter] extracted textarea& to textarea.form-control
}


select,
textarea,
[type="text"],
[type="password"],
[type="datetime"],
[type="datetime-local"],
[type="date"],
[type="month"],
[type="time"],
[type="week"],
[type="number"],
[type="email"],
[type="url"],
[type="search"],
[type="tel"],
[type="color"] {
    @extend %u-form-control;
}

input[readonly].hasDatepicker{
    background-color: $input-bg;
}