/*UC110 Detailed cart*/
$_uc110-main-go-to-action-button-width: 206px; // KlarnaCheckoutButton and MainButtons should have same width. Title "Betal med faktura" is most longest for now ~ 206px is enough.

.UC110FreeShippingMessage{
    @media(min-width: $screen-sm-min){
        float: right;
    }
}
.UC110MainTitle{
    @media(max-width: $screen-xs-max){
        margin-bottom: 0.2em;
    }
}
.UC110ProductListContainer{
    display: flex;
    flex-direction: column;
}
.UC110CartGridItem { //link
    color: $text-color;
    text-decoration: none;
}
.UC110CartGridItemQuantity{ // disabled quantity input box - make it looks like simple text line
    &[disabled="disabled"]{
        @include uni-button-variant($text-color, transparent, transparent);
        box-shadow: none;
        cursor: default;
        padding: 0;
    }
}
.CartGridItemQuantity,
.CartGridItemQuantityDrp,
.BlockTempOrderLinesContainer .QuantityTxb,
.BlockTempOrderLinesContainer .QuantityDrp{
    width: 54px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
}
.CartGridItemPrice{
    white-space: nowrap;
}
.uc110{
    &__back-to-site-btn{
        float: left;
        text-decoration: underline;
        padding-left: 0;
    }
    &-product-list__delete-all-btn,
    &-product-list__update-btn {
        float: right;
        text-decoration: underline;
    }
    &__back-to-site-btn,
    &-product-list__update-btn{
        @extend %u-reset-btn-to-a-link;
    }
    &-product-list__delete-all-btn{
        padding-right: 0;
    }
    &-total-container{
        margin-top: $grid-size * 2;
        line-height: 22px;
        text-align: right;
        &__product-count-label,
        &__total-price-label,
        &__total-discount-label,
        &__total-vat-label,
        &__total-price-including-vat-label{
            font-weight: $label-font-weight;
        }
        &__total-price-value,
        &__total-price-including-vat-value{
            font-weight: $label-font-weight;
            font-size: 16px;
        }
        &__total-price-vat,
        &__total-price-including-vat{
            font-size: 12px;
        }
        //Divider lines
        &__product-count-td{
            padding-bottom: 11px;
            border-bottom: 1px solid $table-border-color;
        }
        &__total-discount-td{
            padding-top: 11px;
            padding-bottom: 12px;
            border-bottom: 1px solid $table-border-color;
        }
        &__total-vat-td{
            padding-top: 8px;
        }
        &__total-price-including-vat-td{
            padding-bottom: 7px;
            font-weight: $label-font-weight;
        }
    }
    /*Buttons handling*/
    .KlarnaCheckoutButton {
        min-width: $_uc110-main-go-to-action-button-width;
        @extend %u-btn-primary;
        @extend %u-btn-size-large;
        margin-bottom: 10px;
    }
    &-checkout__next-btn {
        min-width: $_uc110-main-go-to-action-button-width;
        @extend %u-btn-primary;
        @extend %u-btn-size-large;
    }
}

.UC110SummaryContainer{
    padding-bottom:  $grid-size * 3;
}

.UC110ButtonsContainer {
    border-top: 1px solid $table-border-color;
    padding-top: $grid-size * 3;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.UC110BonusSystem{
    > *:not(:first-child) {
        margin-top: $grid-size * 2;
    }
    @media (min-width: $screen-lg-min) {
        min-width: 400px;
    }
}

/*Delete button*/
.CartGridItemDelete,
.BlockTempOrderLinesContainer .DeleteButton {
    display: block;
    cursor: pointer;
    line-height: 0;
    font-size: 0;
    color: $text-color;
    text-decoration: none;
    &:hover{
        text-decoration: none;
    }
    &::before{
        @extend %fa-close;
        font-size: 16px;
    }
}

@import "uc110-mobile-view";
@import "uc110-discount-codes";
@import "uc110-custom-js-containers";
//@import "uc110-detailed-cart/uc110-shopping-list"; deprecated