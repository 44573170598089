:root {
    --uc212-delivery-selected-border-color: #123f9a;
    --uc212-delivery-selected-background-color: #F4F6FA;
}

.uc212 {
    h2 {
        color: #000;
        font-size: 19px;
    }

    &__delivery-panel-body {
        box-shadow: none;
        border: 0 none;
        @media (max-width: $screen-sm-max) {
            padding: 0;
        }
        @media (min-width: $screen-md-min) { 
            border: .1rem solid #ddd;
        }

        h3 {
            color: red;
        }
    }


    &-preconditions {
        label {
            font-weight: 500;
            display: block;
            font-size: 12px;
        }
    }

}

