/*==========================================
=            UNIMICRO VARIABLES            =
==========================================*/
//Templates design system
$brand-primary-alternative: darken($brand-primary, 10%) !default;
$outline-border-color: $cl_Black !default;

/*----------  TYPOGRAPHY COLORS ----------*/
$heading-h1-mb:             2.5rem !default;

$text-color-gray:           #595959; //Lists, breadcrumbs, menu items
$txt-price-color:           $text-color !default;
$txt-price-weight:          500 !default;
$txt-original-price-color:  $text-color-gray !default;

$label-font-weight: 500 !default;

//Bonus system
$bonus-success-color: $cl_Mystic;
/*----------  COLORS  ----------*/

/* Animation default properties */
$animation-duration:                .1s !default;
$animation-timing-function:         ease-out !default;

/*----------  SITE SEARCH  ----------*/
$site-search__width:              220px !default;
$site-search__height:             40px !default;
$site-search__button-width:       53px !default;
$site-search__button-height:      40px !default;

$site-search__input-padding:      0 40px 0 17px !default;
$site-search__input-color:        $text-color !default;
$site-search__input-font-size:    $font-size-base !default;
$site-search__input-xs-font-size: 16px !default; //Minimal font size to avoid zoom effect on iOS devices is 16px
$site-search__border:             1px solid $text-color !default;

$site-search__icon-font-size:     21px !default;
$site-search__icon-color:         $text-color !default;

//special button
$btn-quick-view-visibility:                default !default; //default | mobile

//Space between quantity box and buy btn
$u-quantity-margin-right:                  7px !default; //temporary solution

/*----------  UC188  ----------*/
$wrp-slide-content-bg: rgba($brand-primary, .7) !default;

/*----------  UC200 File list  ----------*/
$uc200-table-transfrom-point: 0 !default;  // for more complicated design use $screen-sm-min;

/* Horizontal product menu: Same view for all screen sizes */
$PM-line-height:            28px !default;
$PM-sublevel-line-height:   28px !default;