$uc194__comment-gap:                2.4rem !default;

$uc194__avatar-size:                10rem !default;
$uc194__avatar-width:               $uc194__avatar-size !default;
$uc194__avatar-height:              $uc194__avatar-size !default;
$uc194__avatar-gap:                 2.4rem !default;

$uc194__avatar-size_xs:             3.2rem !default;
$uc194__avatar-width_xs:            $uc194__avatar-size_xs !default;
$uc194__avatar-height_xs:           $uc194__avatar-size_xs !default;
$uc194__avatar-gap_xs:              1.2rem !default;

$uc194__replies-border-left:        1px dotted $cl_Alto1 !default;

$uc194__vote-color:                 $cl_Oslo_Gray !default;
$uc194__vote-disabled-color:        $btn-link-disabled-color !default;
$uc194__voted-up-color:             $brand-success !default;
$uc194__voted-down-color:           $brand-danger !default;



.uc194 {
    &-recaptcha2-inline{
        margin-top: 50px;
    }

    &-comment-item,
    &-reply-item,
    &-comments__actions,
    &-replies__actions,
    &-new-reply {
        margin-top: $uc194__comment-gap;
    }

    &-replies {
        border-left: $uc194__replies-border-left;
        margin-left: calc(#{$uc194__avatar-width_xs} / 2 - 1px);
        padding-left: $uc194__avatar-gap_xs;
        @media (min-width: $screen-sm-min) {
            margin-left: calc(#{$uc194__avatar-width} / 2 - 1px);
            padding-left: $uc194__avatar-gap;
        }
        &__actions {
            margin-left: $uc194__avatar-width_xs * 0.5;
            padding-left: $uc194__avatar-gap_xs;
            @media (min-width: $screen-sm-min) {
                margin-left: $uc194__avatar-width * 0.5;
                padding-left: $uc194__avatar-gap;
            }
        }
    }

    &-new-comment,
    &-new-reply,
    &-comment,
    &-reply {
        @extend %u-clearfix;
        &__avatar {
            float: left;
            &-img {
                display: block;
                width: $uc194__avatar-width_xs;
                height: $uc194__avatar-height_xs;
                @media (min-width: $screen-sm-min) {
                    width: $uc194__avatar-width;
                    height: $uc194__avatar-height;
                }
            }
            ~ div {
                margin-left: $uc194__avatar-width_xs + $uc194__avatar-gap_xs;
                @media (min-width: $screen-sm-min) {
                    margin-left: $uc194__avatar-width + $uc194__avatar-gap;
                }
            }
        }
        &__header {
            position: relative;
            margin-bottom: .9rem;
        }
        &__message {
            min-height: $uc194__avatar-size;
            &::placeholder {
                font-style: italic;
            }
        }
        &-message {
            &-editor {
                &__footer {
                    @extend %flex;
                    @extend %flex-right;
                    margin-top: .3rem;
                    border: 1px solid $cl_Alto1;
                    background-color: $cl_Desert_Storm1;
                    border-radius: $border-radius-base;
                }
            }
        }
    }

    &-new-reply,
    &-reply {
        &__replied-person {
            font-style: italic;
        }
    }

    &-new-comment {
        &__message {
            &::placeholder {
                line-height: calc(#{$uc194__avatar-size} - #{$padding-base-vertical} * 2);
                font-size: 2.4rem;
                transition: line-height $animation-duration, font-size $animation-duration;
            }
            &:focus::placeholder {
                line-height: $line-height-base;
                font-size: $font-size-base;
            }
        }
        &-message {
            &-editor {
                &__footer {
                    @extend %flex;
                    @extend %flex-right;
                    padding: 1.2rem 1.4rem;
                }
            }
        }
    }

    &-new-reply {
        margin-left: $uc194__avatar-width_xs * 0.5 + $uc194__avatar-gap_xs;
        @media (min-width: $screen-sm-min) {
            margin-left: $uc194__avatar-width * 0.5 + $uc194__avatar-gap;
        }
        &-message {
            &-editor {
                &__footer {
                    @extend %flex;
                    @extend %flex-right;
                    padding: 1rem;
                }
            }
        }
        &__replied-person {
            line-height: calc(#{$line-height-computed} + #{$padding-small-vertical} * 2); // 2px button borders
        }
    }

    &-comment,
    &-reply {
        &-item {
        }
        &__author {
            font-size: 1.6rem;
            font-weight: 700;
        }
        &__actions {
            position: absolute;
            top: 0;
            right: 0;
            display: none;
        }
        &__actions.open,
        &:hover &__actions {
            display: block;
        }
        &__actions-toggle {
            border: 0;
            background: none;
            color: $cl_Oslo_Gray;
        }
        &__replied-person {
            &::after {
                content: ",";
            }
        }
        &__message,
        &__message-view {
            white-space: pre-wrap;
        }
        &-message {
            &-editor {
                &__footer {
                    padding: 1rem;
                }
            }
        }
        &__date-time {
            color: $cl_Oslo_Gray;
        }

        &__footer {
            margin-top: 1rem;
            overflow: hidden;
        }
        &-footer {
            @extend %flex;
            @extend %flex-item-center;
            &__item {
                @extend %flex;
                @extend %flex-item-center;
                &:not(:first-child) {
                    &::before {
                        margin-left: .9rem;
                        margin-right: .9rem;
                        font-size: .4rem;
                        color: $cl_Oslo_Gray;
                        @extend %fa-circle;
                    }
                }
            }
        }
    }



    /* Action buttons */
    &__more-comments {
        width: 100%;
        padding: 1.1em;
        border: 0;
        font-size: 22px;
        @extend %u-btn;
        @include button-variant($cl_White, $brand-primary, $brand-primary);
    }

    &__more-replies {
        width: 100%;
        padding: $padding-large-vertical $padding-large-horizontal;
        font-weight: 700;
        text-align: left;
        @extend %u-btn;
        @include button-variant($brand-primary, $cl_Desert_Storm1, $cl_Alto1);
        &::after {
            margin-left: .6em;
            font-size: .8rem;
            @extend %fa-chevron-down;
        }
    }

    &-comment__update,
    &-comment__cancel,
    &-new-comment__add,
    &-reply__update,
    &-reply__cancel,
    &-new-reply__add,
    &-new-reply__cancel {
        min-width: 6rem;
        padding: $padding-small-vertical $padding-small-horizontal;
        margin-left: 1rem;
        @extend %u-btn;
    }

    &-comment__update,
    &-new-comment__add,
    &-reply__update,
    &-new-reply__add {
        @include button-variant($cl_White, $brand-primary, $brand-primary);
    }

    &-comment__cancel,
    &-reply__cancel,
    &-new-reply__cancel {
        @include button-variant($text-color, $cl_White, $cl_Alto1);
    }

    &-comment,
    &-reply {
        &__reply,
        &__show-replies {
            padding: 0;
            border: 0;
            background: none;
        }
        &__reply,
        &__show-replies {
            color: $link-color;
            &:hover,
            &:focus {
                color: $link-hover-color;
            }
        }
    }

    /* Vote */
    &-vote {
        padding: 0;
        border: 0;
        background: none;
        color: $uc194__vote-color;
        & + & {
            margin-left: .7rem;
        }
        &_up {
            &_voted,
            &:hover {
                color: $uc194__voted-up-color;
            }
        }
        &_down {
            &_voted,
            &:hover {
                color: $uc194__voted-down-color;
            }
        }
        &[disabled] {
            color: $uc194__vote-disabled-color;
        }
        &__icon {
            vertical-align: middle;
            font-size: $font-size-large;
        }
    }

}