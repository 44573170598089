/*UC171 Document View*/
.doc {

    &__teaser, &__content{
        @include article-formating;
    }
    &__teaser {
        @include article-teaser;
    }
    &__content{
        @include article-content;
    }
    header {
        position: relative;
    }
    // time {
    // }
    &__print-btn {
        display: block;
        margin-top: -0.5em;
        position: absolute;
        top: 50%;
        right: 0;
        font-size: 1.3em;
        text-decoration: none;
    }
    // figure {
    // }
    /* left image align */
    &_left-img-align {
        .doc__images-cont {
            margin-bottom: 1.5rem;

            @media (min-width: $screen-sm-min) {
                padding-right: 4rem;
                float: left;
            }
            @media (max-width: $screen-xs-max) {
                margin-bottom: 3rem;
            }
        }
    }
    /* right image align */
    &_right-img-align {
        .doc__images-cont {
            margin-bottom: 2em;

            @media (min-width: $screen-sm-min) {
                padding-left: 3em;
                float: right;
            }
        }
        .doc__rel-img-list {
            @media (min-width: $screen-sm-min) {
                text-align: right;
            }
        }
        .doc__rel-img-item {
            &:first-child {
                margin-left: 0;
            }
        }
    }
    /* above image align */
    // &_above-img-align {
    // }
    /* under image align */
    &_under-img-align {
        .doc__teaser {
            margin-bottom: 3rem;
        }
    }
    /* some fixes for diferent image align */
    &_left-img-align,
    &_above-img-align,
    &_under-img-align {
        .doc__rel-img-item {
            &:last-child {
                margin-right: 0;
            }
        }
    }
    // &_left-img-align,
    // &_right-img-align {
    // }
    &_above-img-align,
    &_under-img-align {
        .doc__images-cont {
            margin-bottom: 3.5rem;
        }
    }
    &__img {
        position: relative;
        z-index: 1;
    }
    &__teaser,
    &__content {
        img {
            max-width: 100%;
        }
    }
    &__content {
        margin-top: 3rem;
        &-force-show{
            @media (min-width: $screen-sm-min) {
                display: block;
                height: auto !important;
            }
        }
        &-read-more-btn{
            @media (max-width: $screen-xs-max) {
                margin-bottom: 16px;
            }
        }
    }
    &__media-embed {
        margin-top: 3rem;
    }
    &__categories {
        margin-top: 3rem;
    }
    &__snippet {
        margin-top: 3rem;
        + * {
            margin-top: 2rem;
        }
    }
    &__rel-img-list {
        margin-bottom: 0;
        font-size: 0;
    }
    &__rel-img-item {
        display: inline-block;
        margin: .8rem .8rem 0 0;
    }
    &.Slider {
        .doc__snippet {
            margin-top: 2rem;
        }
    }
    .slick-default img {
        width: 100%;
    }

    &.SliderMenu {
        .TopBox {
            &::before {
                font-size: 1.6em;
                @extend %fa-info-circle;
            }
        }
        @media (max-width: $screen-xs-max) {
            border: 1px solid $cl_Celeste;
            .TopBox {
                padding: 10px 15px;
                border-bottom: 1px solid $cl_Celeste;
            }
            .MiddleBox {
                > .Inner {
                    padding: 10px 15px 10px;
                }
            }
        }
    }

}

/* FingerSwiper part in scripts.js :((

.img-cont_swiper::before {
    @extend %un-scrollbar-horizontal;

    @media (min-width: $screen-sm-min) {
        display: none;
    }
}

@media (max-width: $screen-xs-max) {
    .img-cont_swiper {
        display: block;
        width: 100%;
        overflow-x: auto;
        position: relative;
        &::before {
            display: block;
            padding: 0.1em;
            position: absolute;
            top: 10px;
            left: 10px;
            background: rgba(255, 255, 255, 0.5);
            line-height: 10px;
            font-size: 50px;
            color: $cl_Black;
        }
        img {
            width: auto !important;
            max-width: none !important;
            max-height: 300px;
        }

        -webkit-overflow-scrolling: touch
    }
}*/