$enable-premium-register-style: false !default;

.UC197 {
    display: flex;
    flex-direction: column;
    & > .Section:not(:last-child) {
        //TODO: change CompanySection style="display: none;" to smt. else. Not possible to use first-child approach
        margin-bottom: 30px;
    }
    .SaveButtonSection{
        @if $enable-premium-register-style == false {
            display: flex;
            justify-content: flex-end;
        }
    }

    //Repeating blocks spaces
    .DeliveryAddressSection + .DeliveryAddressSection,
    .ContactPersonSection + .ContactPersonSection,
    .AddDeliveryAddressButtonBlock{
        @if $enable-premium-register-style == false {
            margin-top: 30px;
        }
    }

    //Alignment fix for newsletter checkbox
    .CompanyReceiveNewsletterRow,
    .ContactReceiveNewsletterRow{
        @if $enable-premium-register-style == false {
            @media (min-width: $screen-sm-min) {
                align-items: flex-start !important;
                margin-top: 14px !important;
                .checkbox{
                    margin-top: 0;
                }
            }
        }
    }
    .ContactReceiveNewsletterPrivacyPolicy{
        font-weight: $label-font-weight;
    }

        //come common styles for this control
    label{ //.FormLabel + errors are rendered as labels also
        font-weight: inherit;
        margin-bottom: 0;
    }
    .Star {
        vertical-align: super;
        line-height: normal;
        font-size: 14px;
    }
    .FormSelect {
        margin-top: 3px;
    }

    //KEY-VALUE containers general floating
    .Row{
        display: flex;
        flex-wrap: wrap;
        @if $enable-premium-register-style == false {
            &:not(:first-child){
                margin-top: 8px;
            }
        }
        @media (min-width: $screen-sm-min) {
            align-items: center;
            flex-wrap: nowrap;
        }
    }
    .DetailsKeyContainer {
        flex-grow: 1;
        @media (min-width: $screen-sm-min) {
            text-align: right;
        }
    }
    .DetailsValueContainer {
        width: 100%;
        @media (min-width: $screen-sm-min) {
            margin-left: 18px;
            width: 53%;
        }
    }

    //MAIN CONTACT PERSON
    .ContactPersonSection{
        @if $enable-premium-register-style == false {
            & > *:not(:first-child) {
                margin-top: 20px;
            }
        }
    }
    .ContactPersonGender {
        @if $enable-premium-register-style == false {
            line-height: $input-height-base;
            padding-left: 0;
            margin-bottom: 0;
            &Option {
                display: inline-block;
                &:not(:first-child) {
                    margin-left: 15px;
                }
                input[type="radio"] {
                    margin-right: 4px;
                }
                label {
                    font-weight: 400;
                    margin-bottom: 0;
                }
            }
        }
    }
    .ContactReceiveNewsletterRow {
        @if $enable-premium-register-style == false {
            margin-top: 11px;
        }
    }
    .ButtonResetpassword {
        @if $enable-premium-register-style == false {
            @extend %u-reset-btn-to-a-link;
            text-decoration: underline;
        }
    }

    //MAIN CONTACT PERSON - main action buttons
    .SaveCustomerButtonBlock{
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
    }
    .ButtonDeletecustomer,
    .ButtonSavecustomer{
        text-transform: uppercase;
        border-radius: $border-radius-base;
    }

    .AddContactButtonBlock {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
    }
    .ButtonAddcontact {
        @if $enable-premium-register-style == false {
            padding: 0;
            font-size: 1.4em;
            @extend %u-reset-btn-to-a-link;

            &::before{
                font-size: .8em;
                margin-right: 10px;
                @extend %fa-plus;
            }
        }
    }
    .ButtonCanceladdcontact{
        @if $enable-premium-register-style == false {
            @extend %u-reset-btn-to-a-link;
            font-size: 1.4em;
            margin-left: auto;
            text-decoration: underline;
        }
    }
    .ButtonSavecustomer{
        margin-left: auto;
    }


    //COMPANY INFO BLOCK
    .CompanyInfoBlock{
        @if $enable-premium-register-style == false {
            @extend %card-default-style;
            @extend %card-padding-base;
            display: flex;
            flex-direction: column;
        }

        
    }
    .FacturaAddressHeaderRow{
        @media (min-width: $screen-sm-min) {
            margin-top: 20px !important;
            margin-left: auto;
            width: calc(56.25% - 19px);
            padding-left: 8px;
        }
    }
    .SaveCompanyButtonBlock{
        display: flex;
        margin-top: 20px;
    }
    .ButtonSavecompany{
        margin-left: auto;
    }

    //DELIVERY ADDRESS BLOCK
    .DeliveryAddressSection{
        @if $enable-premium-register-style == false {
            @extend %card-default-style;
            @extend %card-padding-base;
        }
    }
    .ContactPersonSection {
        @if $enable-premium-register-style == false {
            @extend %card-default-style;
            @extend %card-padding-base;
        }

        display: flex;
        flex-direction: column;
    }
    .SaveDeliveryAddressButtonBlock{
        margin-top: 20px;
        display: flex;
    }
    .ButtonSavedeliveryaddress{
        margin-left: auto;
    }


    //ANONYMOUS SITE VISITOR
    .RegistrationTypeRow {
        //@include registration-type(inline, 36px, 8px);
        @extend %registration-type;
        
        @if $enable-premium-register-style == false {
            margin-bottom: 30px;
        }
    }
    //Action button section
    .RegisterButtonSection {
        @if $enable-premium-register-style == false {
            @extend %card-default-style;
            @extend %card-padding-base;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
    .AcceptConditionsContainer {
        display: flex;
        flex-wrap: wrap;
        input{
            margin-right: 6px;
        }
        a {
            text-decoration: underline;
            font-weight: $label-font-weight;
        }
        label.error{
            margin: 0 5px;
            padding: 0 5px;
            color: $cl_White;
            border-radius: $border-radius-base;
            @include label-variant($label-danger-bg);
        }
    }
    .RegisterButtonBlock {
        @if $enable-premium-register-style == false {
            margin-top: 8px;
            @media (min-width: $screen-sm-min) {
                margin-top: 0;
                margin-left: auto;
            }
            @media (max-width: $screen-xs-max) {
                width: 100%;
            }
        }
    }
    .ButtonRegisternewcustomer{
        @media (max-width: $screen-xs-max) {
            width: 100%;
        }
    }
    .ButtonRegisternewcustomer,
    .ButtonSave{
        @extend %u-btn-primary;
        @extend %u-btn-size-large;
        min-width: $u-buy-btn-min-width-large;
    }

    @media (max-width: $screen-xs-max) {
        .FormLabel,
        .FormBox,
        .FormMultiBox {
            font-size: 16px; //avoid iOS zoom in
        }
    }
    .PersonalData{
        a{
            text-decoration: underline;
        }
    }
    .DeliveryAddressButtonsBlock{
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
    }
}