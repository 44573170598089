/*
    Use <div class="banner">
*/

.banner {
    .UC188 {
        .ListContainer {
            display: flex;
            flex-wrap: nowrap;
            margin-bottom: 20px;
            overflow: hidden;

            .Item {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                flex: 0 0 100%;

                .ImageContainer {
                    margin-right: 10px;
                }

                .btn {
                    padding: 0;
                    color: $cl_Black;
                }

                .Text {
                    margin-left: 15px;
                }

                .Image {
                    max-width: 30px;
                }

                p {
                    margin-bottom: 0;
                }

                &:last-child {
                    border-right: 0;
                }

                @media (max-width: $screen-xs-max) {
                    animation-name: slide-in;
                    animation-duration: 15s;
                    animation-iteration-count: infinite;
                    animation-delay: 5s;
                    animation-timing-function: cubic-bezier(0.25,0.1,2,1);

                    @keyframes slide-in {
                        0% { transform: translateX(0); }
                        25%,
                        50% { transform: translateX(-100%); }
                        75%,
                        100% { transform: translateX(0); }
                    }
                }

                @media (min-width: $screen-sm-min) {
                    flex: 0 0 50%;
                    border-right: 1px solid #ddd;
                }
            }

            @media (min-width: $screen-sm-min) {
                margin-bottom: 35px;
            }
        }
    }
}
