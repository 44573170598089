/*COMMON for several controls
UC178: ResellersBuilder
UC181: Order details - outdated
ProdRelCustomFieldsGrid.ascx file
..and? */

@media (max-width: $screen-xs-max) {
    .ResponsiveGridV1 {
        line-height: 1.2;
        font-size: 12px;
        thead,
        .Header {
            display: none;
        }
        tr:nth-child(2n) {
            background-color: $cl_White;
        }
        td {
            display: block;
            width: 100%;
            min-height: 1.7em;
            padding: 0.25em 0.6em 0.25em 35%;
            position: relative;
            border-top: 0;
            border-bottom: 1px solid $cl_Wild_Sand1;
            &:before {
                display: block;
                content: "";
                content: attr(data-title);
                width: 33%;
                padding: 0.25em 0.6em;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                background: none repeat scroll 0 0 $cl_Tundora4;
                color: $cl_White;
            }
        }
        tr:not(:first-child) td:nth-child(1) {
            margin-top: 20px;
            border-top: 1px solid $cl_Wild_Sand1;
        }
    }
}