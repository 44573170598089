.site-header-desktop {

    .profile-quick-menu {
        &,
        &__btn {
            display: flex;
            align-self: center;
        }

        &__un-user {
            @extend %site-header-desktop-btn;
            &::before {
                font-size: inherit;
            }
        }

        &__dropdown {
            top: 100%;
        }
    }

}