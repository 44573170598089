// default flag properties
$flag__type:                            rectangle !default; // rectangle, circle, square
$flag__position:                        .8rem auto auto 0 !default; // top, right, bottom, left
$flag__size:                            3.4rem !default; // height=size, width=auto for rectangle shape, height=width=size for circle and square
$flag__padding:                         0 .8rem !default;
$flag__font:                            $font-size-small 700 normal !default; // font-size, font-weight, font-style

// large flag properties
$flag-lg__type:                         $flag__type !default; // rectangle, circle, square
$flag-lg__position:                     1.6rem auto auto 0 !default; // top, right, bottom, left
$flag-lg__size:                         4rem !default; // height=size, width=auto for rectangle shape, height=width=size for circle and square
$flag-lg__padding:                      0 1.1rem !default;
$flag-lg__font:                         $font-size-base 700 normal !default; // font-size, font-weight, font-style

// discount percent flag properties
$flag-discount-percent__type:           circle !default; // rectangle, circle, square
$flag-discount-percent__position:       .8rem .8rem auto auto !default; // top, right, bottom, left
$flag-discount-percent__size:           3.4rem !default; // height=size, width=auto for rectangle shape, height=width=size for circle and square
$flag-discount-percent__padding:        0 1rem !default;
$flag-discount-percent__font:           $font-size-small 700 normal !default; // font-size, font-weight, font-style

// large discount percent flag properties
$flag-discount-percent-lg__type:        circle !default; // rectangle, circle, square
$flag-discount-percent-lg__position:    1.6rem 1.6rem auto auto !default; // top, right, bottom, left
$flag-discount-percent-lg__size:        4rem !default; // height=size, width=auto for rectangle shape, height=width=size for circle and square
$flag-discount-percent-lg__padding:     0 1rem !default;
$flag-discount-percent-lg__font:        $font-size-base 700 normal !default; // font-size, font-weight, font-style

// discount percent flag properties
$flag-bonus__type:           circle !default; // rectangle, circle, square
$flag-bonus__position:       .8rem .8rem auto auto !default; // top, right, bottom, left
$flag-bonus__size:           6rem !default; // height=size, width=auto for rectangle shape, height=width=size for circle and square
$flag-bonus__padding:        0 !default;
$flag-bonus__font:           $font-size-small 400 normal !default; // font-size, font-weight, font-style

$flag-theme-colors: () !default;
$flag-theme-colors: map-merge(
    (
            default: (
                    colors: (
                            background-color: $cl_Tuatara,
                            color: $cl_White,
                    ),
            ),
            discount-percent: (
                    colors: (
                            background-color: $cl_Coral_Red,
                            color: $cl_White,
                    ),
            ),
            bonus: (
                    colors: (
                            background-color: $cl_White,
                            color: $cl_Black,
                    ),
            ),
            new: (
                    id: 1,
                    colors: (
                            background-color: $cl_Inch_Worm,
                            color: $cl_White,
                    ),
            ),
            discount: (
                    id: 2,
                    colors: (
                            background-color: $cl_Gallery1,
                            color: $cl_Coral_Red,
                    ),
            ),
    ),
    $flag-theme-colors
);

@import "m-flag";
@import "e-flag";
@import "c-flags";