.UC195 {
    .MainTitle {
        margin-bottom: 0;
        &::before {
            display: none;
            @extend %fa-filter;
        }
        @media(max-width: $screen-xs-max) {
            line-height: 40px;
            font-size: 20px;
            &::before {
                display: block;
                position: absolute;
                top: 50%;
                left: 10px;
                transform: translateY(-50%);
                font-size: .8em;
            }
        }
    }
    .ui-widget-content {
        margin-left: 8px;
        margin-right: 8px;
    }
    .ui-slider-range {
        background-color: $brand-primary;
    }
    .ApplyFilters {
        margin-right: 10px;
    }
    .ResetFilters {
        padding-left: 0;
        background-color: transparent;
        color: $link-color;
    }

    /* --- for 'default' and 'horizontal' templates --- */
    h3 {
        margin-bottom: calc(#{$line-height-computed} / 2);
        font-size: $font-size-base;
    }
    .FilterCriteria {
        label {
            font-weight: normal;
        }
    }
    /* --- for 'default' and 'horizontal' templates --- */

}