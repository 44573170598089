.site-search {
    position: relative;

    &__input {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__btn {
        padding: 0;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        font-size: 0;
        background-color: transparent;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        &::after {
            @extend %un-search-slim;
        }
    }
}
