/* UC177 Menu */
/*
use classes:
  SliderMenu - to create slider menu
  ProductsIcon - to add product's icon
  DocumentsIcon - to add document's icon
*/
$UC177_fs: 13px;
$UC177_lh: 20px;
$UC177_padding: 10px;

.UC177 {
    .TopBox {
        padding: 10px 15px;
    }
    .MainTitle,
    .MenuTitle {
        font-size: $font-size-large;
    }
    h2{
        margin: 0;
    }
    ul {
        @extend %u-list-unstyled;
    }
    li {
        display: block;
        position: relative;
    }
    a {
        text-decoration: none;
    }
    .Name {
        display: block;
        padding: $UC177_padding;
        line-height: $UC177_lh;
        font-size: $UC177_fs;
    }
    .Toggle {
        padding: $UC177_padding;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        transition: transform 0.3s;
        line-height: $UC177_lh;
        font-size: 8px;
        &::after {
            @extend %fa-chevron-down;
        }
    }
    .Expandable {
        > .Toggle {
            transform: translateZ(0) rotate(0);
        }
        > ul {
            display: none;
        }
    }
    .Expanded {
        > .Toggle {
            transform: translateZ(0) rotate(-180deg);
        }
        > ul {
            display: block;
        }
    }
    .Level-1 {
        > li:not(:first-child) {
            border-top: 1px solid $table-border-color;
        }
    }
    .Level-2 {
        .Name {
            font-size: $UC177_fs - 1;
        }
        .Toggle {
            font-size: 7px;
        }
        .Name,
        .Toggle {
            padding-top: $UC177_padding * 0.5;
            padding-bottom: $UC177_padding * 0.5;
        }
        ul {
            padding-left: $UC177_padding;
        }
    }
    &.NotExpandable {
        .Toggle {
            display: none;
        }
    }
}