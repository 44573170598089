$site-payment-icons-space:       4px !default;
$site-payment-icons-space_sm:    8px !default;
$site-payment-icons-space_md:    13px !default;

.site-payment {
    &-images {
        .ListContainer {
            @extend %flex;
            @extend %flex-wrap;
            @extend %flex-center;
            @extend %flex-item-center;
        }

        .Item {
            &:not(:first-of-type) {
                margin-left: $site-payment-icons-space;

                @media (min-width: $screen-sm-min) {
                    margin-left: $site-payment-icons-space_sm;
                }

                @media (min-width: $screen-md-min) {
                    margin-left: $site-payment-icons-space_md;
                }
            }
        }
    }
}
