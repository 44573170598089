$cl_Black: #000;
$cl_Daintree: #002133;
$cl_Black_Pearl: #0b132b;
$cl_Cerulean: #00b3ee;
$cl_Cerulean1: #01a0d5;
$cl_Cerulean2: #00baf2;
$cl_Cerulean3: #00b2e3;
$cl_Cerulean4: #0B7DAE;
$cl_Cerulean5: #00689F;
$cl_Allports: #006aa1;
$cl_Alto: #dddddd;
$cl_Congress_Blue: #003e7e;
$cl_Japanese_Laurel: #008000;
$cl_Lochmara: #008bca;
$cl_Lochmara1: #0073be;
$cl_Pacific_Blue: #09c;
$cl_Pacific_Blue1: #009EE2;
$cl_Bahama_Blue: #056b8d;
$cl_Bahama_Blue1: #015b96;
$cl_Bahama_Blue2: #205080;
$cl_Bahama_Blue3: #1E4C76;
$cl_Deep_Cerulean: #057ca3;
$cl_Biscay: #1b3667;
$cl_Biscay1: #2C424D;
$cl_Woodsmoke: #111;
$cl_Woodsmoke1: #0e0e0f;
$cl_Bunker: #121212;
$cl_Bunker1: #151515;
$cl_Bunker2: #232526;
$cl_Blue_Zodiac: #15284b;
$cl_Blue_Zodiac1: #0f2d52;
$cl_Ebony_Clay: #1e253a;
$cl_Log_Cabin: #222;
$cl_Shark: #282828;
$cl_Shark1: #242424;
$cl_Baltic_Sea: #2b2b2b;
$cl_Charade: #2b2e34;
$cl_Baltic_Sea1: #2c2b2b;
$cl_Baltic_Sea2: #404345;
$cl_Heavy_Metal: #2c2c2c;
$cl_Heavy_Metal1: #2d2d2a;
$cl_St_Tropaz: #2e5f8d;
$cl_St_Tropaz1: #1b5192;
$cl_St_Tropaz2: #32597E;
$cl_Bleached_Cedar: #2f212e;
$cl_Pickled_Bluewood: #2f4858;
$cl_San_Juan: #244a6e;
$cl_Curious_Blue: #25a6d0;
$cl_Curious_Blue1: #2996e2;
$cl_Paradiso: #28808b;
$cl_Mine_Shaft: #303030;
$cl_Calypso: #31708f;
$cl_Calypso1: #226787;
$cl_Mine_Shaft1: #323232;
$cl_William: #326771;
$cl_Mine_Shaft2: #333;
$cl_Mine_Shaft3: #2e2e2e;
$cl_Mine_Shaft4: #323132;
$cl_Mine_Shaft5: #2c3031;
$cl_Keppel: #33aca9;
$cl_Tuatara: #363636;
$cl_Tuatara1: #373734;
$cl_Tuatara2: #393939;
$cl_Tuatara3: #383838;
$cl_Masala: #3c3c3c;
$cl_Masala1: #3a3a3a;
$cl_Killarney: #3c763d;
$cl_Mineral_Green: #3d5f4b;
$cl_Cape_Cod: #404446;
$cl_Cape_Cod1: #424242;
$cl_Tundora: #444;
$cl_Tundora1: #454545;
$cl_Gun_Powder: #464153;
$cl_Tundora2: #464545;
$cl_Tundora3: #464646;
$cl_Gravel: #464747;
$cl_Tundora4: #474546;
$cl_Tundora5: #484848;
$cl_Gravel1: #474747;
$cl_Gravel2: #494949;
$cl_Gravel3: #4a4a4a;
$cl_Fuscous_Gray: #504e4f;
$cl_Mortar: #513c50;
$cl_Mortar1: #5B5B5B;
$cl_Fuscous_Gray1: #525252;
$cl_Fuscous_Gray2: #555;
$cl_Chicago: #565656;
$cl_Chicago1: #575657;
$cl_Chicago2: #585858;
$cl_Chicago3: #595758;
$cl_Viking: #5bc0de;
$cl_Chicago4: #5c5c5c;
$cl_Breaker_Bay: #5ca797;
$cl_Fern: #5cb85c;
$cl_Chicago5: #5d5d5d;
$cl_Chicago6: #5e5e5f;
$cl_Chicago7: #5f5d5e;
$cl_Chicago8: #555757;
$cl_Chicago9: #595959;
$cl_Storm_Dust: #606667;
$cl_Storm_Dust1: #646464;
$cl_Scarpa_Flow: #614a5f;
$cl_Ironside_Gray: #666;
$cl_Aqua_Forest: #66a07e;
$cl_Aqua_Forest1: #5caa7f;
$cl_Cornflower_Blue: #66afe9;
$cl_Cornflower_Blue1: #403B63;
$cl_Ferra: #684c48;
$cl_Dove_Gray: #696969;
$cl_Dove_Gray1: #6c6b6b;
$cl_Dove_Gray2: #6c6c6c;
$cl_Pale_Sky: #6d7778;
$cl_Salt_Box: #70546e;
$cl_Tapa: #757575;
$cl_Tapa1: #777;
$cl_Pablo: #79725d;
$cl_Boulder: #7a7a7a;
$cl_Studio: #7a43b6;
$cl_Concord: #7b7b7b;
$cl_Pueblo: #7e260e;
$cl_Gray: #7e7e7e;
$cl_Oslo_Gray: #818a91;
$cl_Gray1: #828282;
$cl_Gunsmoke: #848484;
$cl_Atlantis: #85c63f;
$cl_Suva_Gray: #888;
$cl_Suva_Gray1: #8C8B8B;
$cl_Stack: #898989;
$cl_Pesto: #8a6d3b;
$cl_Stack1: #8d8d8d;
$cl_Stack2: #8f8f8f;
$cl_Mountain_Mist: #919191;
$cl_Stack3: #928f8c;
$cl_Mountain_Mist1: #929292;
$cl_Mountain_Mist2: #969193;
$cl_Mountain_Mist3: #969696;
$cl_Mountain_Mist4: #979797;
$cl_Mountain_Mist5: #999;
$cl_Star_Dust: #9a9a9a;
$cl_Star_Dust1: #9c9c9c;
$cl_Star_Dust2: #9f9f9f;
$cl_Silver_Chalice: #aaa;
$cl_Silver_Chalice1: #acacac;
$cl_Bright_Red: #ad0001;
$cl_Silver_Chalice2: #adadad;
$cl_Sandal: #ad9b6f;
$cl_Silver_Chalice3: #aeaeae;
$cl_Star_Dust3: #a0a0a0;
$cl_Conifer: #a2d757;
$cl_Delta: #a4a4a4;
$cl_Sandal1: #a58d69;
$cl_Shady_Lady: #a6a6a6;
$cl_Shady_Lady1: #a8a8a8;
$cl_Cloudy: #a8a3a3;
$cl_Shady_Lady2: #a9a9a9;
$cl_Apple_Blossom: #a94442;
$cl_Inch_Worm: #afd723;
$cl_Tide: #babbb3;
$cl_Silver: #bdbebe;
$cl_Silver1: #bebebe;
$cl_Silver2: #bfbfbf;
$cl_Silver_Chalice4: #b0b0b0;
$cl_Bombay: #b3b6b5;
$cl_Nobel: #b4b4b4;
$cl_Nobel1: #b6b6b6;
$cl_Nobel2: #9C9B9B;
$cl_Pink_Swan: #bbb;
$cl_Pink_Swan1: #b7b7b7;
$cl_Milano_Red: #b80c09;
$cl_Tia_Maria: #cb3f1f;
$cl_Guardsman_Red: #cc0001;
$cl_Celeste: #ccc;
$cl_Celeste1: #cecfcf;
$cl_Malta: #c0b59d;
$cl_Cotton_Seed: #c0bdb5;
$cl_Rio_Grande: #c0d200;
$cl_Silver3: #c0c0c0;
$cl_Silver4: #c1c1c1;
$cl_Gray_Suit: #c1c4c9;
$cl_Silver_Sand: #c2c2c2;
$cl_Silver_Sand1: #c3c3c3;
$cl_Hibiscus: #c3325f;
$cl_Brick_Red: #c33c54;
$cl_Cloud: #c4c4c4;
$cl_Maroon_Flush: #c7254e;
$cl_Maroon: #452E00;
$cl_Pumice: #c5c5c5;
$cl_Pumice1: #c8c8c8;
$cl_Pumice2: #c9c9c9;
$cl_Cardinal: #c91330;
$cl_Cardinal1: #B91B2F;
$cl_Cardinal2: #C61D23;
$cl_Jagged_Ice: #c8ece3;
$cl_Meteor: #ce7c02;
$cl_Quill_Gray: #d1d1d1;
$cl_Quill_Gray1: #d4d4d4;
$cl_Alto1: #dbdbdb;
$cl_Alto2: #dcdcdc;
$cl_Alto3: #ddd;
$cl_Alto4: #D2CBCB;
$cl_Alto5: #CBC7C7;
$cl_Mystic: #ddecf3;
$cl_Willow_Brook: #dff0d8;
$cl_Punch: #d2401e;
$cl_Valencia: #d54b3a;
$cl_Valencia1: #D33F49;
$cl_Chestnut_Rose: #d9534f;
$cl_Iron: #d8d8d8;
$cl_Link_Water: #d9edf7;
$cl_Peppermint: #e0ffdf;
$cl_Gallery: #eee;
$cl_Gray_Nurse: #e8e8e8;
$cl_Chenin: #e6d266;
$cl_Gray_Nurse1: #e9e9e9;
$cl_Gray_Nurse2: #eaeaea;
$cl_Alizarin_Crimson: #ea2b1f;
$cl_Alizarin_Crimson1: #d72638;
$cl_Alizarin_Crimson2: #D7262A;
$cl_Frostee: #eaf7e5;
$cl_Cararra: #ebebeb;
$cl_Tan_Hide: #eb964e;
$cl_Cararra: #ececec;
$cl_Gallery1: #eceeef;
$cl_Gallery2: #eceef0;
$cl_Gallery3: #ededed;
$cl_Red_Ribbon: #ed193a;
$cl_Gallery4: #efefef;
$cl_Gallery5: #f0f0f0;
$cl_Bon_Jour: #e0e0e0;
$cl_Bon_Jour1: #e2e2e2;
$cl_Mercury: #e1e4e8;
$cl_Mercury1: #e3e3e3;
$cl_Mercury2: #e4e4e4;
$cl_Mercury3: #e5e5e5;
$cl_Mercury4: #e6e6e6;
$cl_Mercury5: #e7e7e7;
$cl_Froly: #e77577;
$cl_Alabaster1: #fafafa;
$cl_Coral_Red: #fb3640;
$cl_Alabaster2: #fbfbfb;
$cl_Alabaster3: #fcfcfc;
$cl_Alabaster4: #F7F7F2;
$cl_Off_Yellow: #fcf8e3;
$cl_Your_Pink: #fdc5c5;
$cl_Macaroni_and_Cheese: #feb476;
$cl_White1: #fefefe;
$cl_Cumulus: #feffd6;
$cl_White: #fff;
$cl_White_Lilac: #EDECF0;
$cl_White_Lilac1: #EFEFF0;
$cl_Wisp_Pink: #fff4f6;
$cl_Gorse: #fff14b;
$cl_School_bus_Yellow: #ffdd03;
$cl_Gorse1: #ffe643;
$cl_Gorse2: #feea4b;
$cl_Red: #f00;
$cl_Red1: #E30000;
$cl_Radical_Red: #ff206e;
$cl_Casablanca: #f0ad4e;
$cl_Seashell: #f1f1f1;
$cl_Vanilla_Ice: #f2dede;
$cl_Concrete: #f2f2f2;
$cl_Concrete1: #f3f3f3;
$cl_Wild_Sand: #f4f4f4;
$cl_Wild_Sand1: #f4f4f7;
$cl_Wild_Sand2: #f5f5f5;
$cl_Wild_Sand3: #f4f4f6;
$cl_Wild_Sand4: #EDEAE6;
$cl_Flamingo: #ff5128;
$cl_Flamingo1: #e95e1d;
$cl_Coral: #f67953;
$cl_Black_Haze: #f6f6f6;
$cl_Black_Haze1: #f7f7f7;
$cl_Geraldine: #f78888;
$cl_Desert_Storm: #f8f7f7;
$cl_Desert_Storm1: #f8f8f8;
$cl_Desert_Storm2: #F7F5F3;
$cl_Fantasy: #f9f2f4;
$cl_Alabaster: #f9f9f9;
$cl_Rose: #f0037f;
$cl_Persian_Red: #d12b33;
$cl_Persian_Red1: #d2322d;
$cl_Mystic: #E5EFEF;
$cl_Mystic1: #D6DCD8;
$cl_Mystic2: #CDD6D0;
$cl_Tall_Poppy: #b42a26;
$cl_Midnight: #02182b;
$cl_Citron: #8ea604;
$cl_Nevada: #676e77;
$cl_Astral: #297ea6;
$cl_Zeus: #272223;
$cl_Sinbad: #9ec8db;
$cl_Trinidad: #e35205;
$cl_Abbey: #4b4f54;
$cl_Flame_Pea: #e0592b;
$cl_Blackcurrant: #2e2e3a;
$cl_Blackcurrant1: #191136;
$cl_Thunderbird: #ba1b1d;
$cl_Thunderbird1: #da291c;
$cl_Thunderbird2: #db2309;
$cl_Thunderbird3: #ce2209;
$cl_Oracle: #32746d;
$cl_Firefly: #0c232a;
$cl_Salem: #049948;
$cl_Salem1: #0B8748;
$cl_Green_Haze: #04a94f;
$cl_Fun_Green: #016c32;
$cl_Chathams_Blue: #0d4e7f;
$cl_Orange_Roughy: #be4e1a;
$cl_Genoa: #136f63;
$cl_Mantis: #6dbe4b;
$cl_Azure_Radiance: #1877f2;
$cl_Orange: #ff671d;
$cl_Red_Damask: #ed6445;
$cl_Burnt_Sienna: #ee6545;
$cl_Burnt_Orange: #FF6C2F;
$cl_Slate_Gray: #69868d;
$cl_Persimmon: #E85200;
$cl_Persimmon1: #EE660B;
$cl_Persimmon2: #DB5305;
$cl_Nero: #212121;
$cl_Nero1: #262626;
$cl_Nero2: #1F1F1F;
$cl_Nero3: #191919;
$cl_Nero4: #1A1A1A;
$cl_Nero5: #232020;
$cl_Nero6: #231F20;
$cl_Nero7: #171717;
$cl_Sunshade: #F8A644;
$cl_Regal_Blue: #1B264F;
$cl_Regal_Blue1: #20325A;
$cl_Cobalt: #0053A0;
$cl_Cobalt1: #003ACC;
$cl_Cobalt2: #0055AA;
$cl_Dark_Cerulean: #034384;
$cl_Dark_Cerulean1: #0F5991;
$cl_Dark_Cerulean2: #096496;
$cl_Bondi_Blue: #0584C0;
$cl_Dim_Gray: #707070;
$cl_Cascade: #8EAC9F;
$cl_County_Green: #1E593F;
$cl_County_Green1: #205929;
$cl_Dew: #EDF5EF;
$cl_Surf: #B9D6BF;
$cl_Gamboge: #F07C0D;
$cl_Egyptian_Blue: #123382;
$cl_Egyptian_Blue1: #123F9A;
$cl_Cerulean_Blue: #2541B2;
$cl_Revolver: #363537;
$cl_Revolver1: #373738;
$cl_Prussian_Blue: #021747;
$cl_Prussian_Blue1: #001536;
$cl_Prussian_Blue2: #002053;
$cl_Prussian_Blue3: #013674;
$cl_Prussian_Blue4: #003C70;
$cl_Prussian_Blue5: #014A71;
$cl_Prussian_Blue6: #010751;
$cl_Prussian_Blue7: #033C5C;
$cl_Prussian_Blue8: #023671;
$cl_Amulet: #7D9D6A;
$cl_Tom_Thumb: #4B603F;
$cl_Cinnabar: #E4572E;
$cl_Cinnabar1: #E23735;
$cl_Free_Speech_Red: #C20114;
$cl_Free_Speech_Red1: #B20016;
$cl_Free_Speech_Red2: #CD0000;
$cl_Free_Speech_Red3: #BC0101;
$cl_Free_Speech_Red4: #BA1200;
$cl_Free_Speech_Red5: #AF1A01;
$cl_Free_Speech_Red6: #BF0000;
$cl_Free_Speech_Red7: #B30000;
$cl_Smalt: #004699;
$cl_Smalt1: #022E9B;
$cl_Cyprus: #0E2745;
$cl_Tyrian_Purple: #0D0106;
$cl_Solitude: #E6E6F2;
$cl_Haiti: #171219;
$cl_Pomegranateapprox: #EF3E33;
$cl_Punch: #D9352B;
$cl_Olive: #5A3C00;
$cl_Olive1: #578207;
$cl_Chocolate: #D37716;
$cl_Chocolate1: #C36F16;
$cl_Romance: #F5F2EA;
$cl_Navy_Blue: #0070E0;
$cl_Navy: #03045E;
$cl_Sherpa_Blue: #002240;
$cl_Sherpa_Blue1: #003E40;
$cl_Glacier: #769CBE;
$cl_Rolling_Stone: #687A7A;
$cl_Kilamanjaro: #3D3A35;
$cl_Chestnut: #BC4B51;
$cl_Dune: #4E4D4C;
$cl_Oxley: #5C946E;
$cl_Brilliant_Rose: #FB5AA5;
$cl_Heather: #BFCED6;
$cl_Timberwolf: #D7D2CB;
$cl_Shiraz: #A90329;
$cl_Chilean_Fire: #ff7502;
$cl_Mexican_Red: #A4243B;
$cl_Powder_Ash: #BCC9C5;
$cl_Jewel: #0B5D1E;
$cl_Jewel1: #0D543C;
$cl_Windsor: #41247B;
$cl_Crusoe: #23651A;
$cl_Blue_Lagoon: #005D8F;
$cl_Deep_Sea: #126C86;
$cl_Night_Rider: #313131;
$cl_Tangaroa: #1C2A38;
$cl_Fun_Blue: #2D467E;
$cl_Blue_Whale: #1D2947;
$cl_Lavender_Blue: #B5CDFF;
$cl_Matisse: #375172;
$cl_Turquoise_Blue: #5BD0E6;
$cl_Cinder: #080909;
$cl_Fire_Brick: #AE201B;
$cl_Fire_Brick1: #B5201F;
$cl_Fire_Brick2: #A31621;
$cl_Falu_Red: #961D12;
$cl_Zuccini: #153C1C;
$cl_Violent_Violet: #2B2345;
$cl_Sea_Green: #359644;
$cl_Ottoman: #CFDACD;
$cl_Gondola: #343434;
$cl_Dark_Green: #063527;
$cl_Dark_Green1: #000422;
$cl_Dark_Green2: #000328;
$cl_Dark_Green3: #001019;
$cl_Dark_Green4: #001022;
$cl_Rust: #B34115;
$cl_Rust1: #AA3304;
$cl_Pine_Green: #037171;
$cl_Alizarin: #E32C2B;
$cl_Alizarin1: #EC1C23;
$cl_Selective_Yellow: #F8B101;
$cl_Energy_Yellow: #F5C856;
$cl_Black_Russian: #1C1E21;
$cl_Hunter_Green: #355E41;
$cl_Everglade: #25432D;
$cl_Carrot_Orange: #F37320;
$cl_Sangria: #A01102;
$cl_Endeavour: #315F97;
$cl_Endeavour1: #275E91;
$cl_Feldgrau: #4D5850;
$cl_Corduroy: #3D453F;
$cl_Deep_Bronze: #4D3B2B;
$cl_Narvik: #EFEDE6;
$cl_Pigment_Green: #008D49;
$cl_Pigment_Green1: #029B35;
$cl_Orient: #244B6F;
$cl_Eggplant: #A90040;
$cl_Brown: #A7282D;
$cl_Denim: #156395;
$cl_Fire_Engine_Red: #CF1623;
$cl_Fire_Engine_Red1: #E50E16;
$cl_Kelly_Green: #4B840E;
$cl_Raw_Umber: #42720E;
$cl_Mosque: #005759;
$cl_Venetian_Red: #D70A28;
$cl_Venetian_Red1: #C51D05;
$cl_Venetian_Red2: #BC0D14;
$cl_Midnight_Express: #131925;
$cl_Teal: #01748A;
$cl_Bubbles: #E4F0EA;
$cl_Tarawera: #293E4B;
$cl_Blumine: #395A6F;
$cl_Elf_Green: #1E8368;
$cl_British_Racing_Green: #004316;
$cl_Gainsboro: #E4E1E1;
$cl_Sea_Pink: #D98083;
$cl_Bali_Hai: #849FAC;
$cl_Deep_Cerise: #E52189;
$cl_Matterhorn: #4E4E4E;
$cl_Pink_Flare: #DDB6B7;
$cl_Kashmir_Blue: #557584;
$cl_Crimson: #ED1C24;
$cl_Grey: #818181;
$cl_Tenne: #C63C02;
$cl_Bastille: #2E2E2F;
$cl_Yellow_Sea: #F7943E;
$cl_Yellow_Sea1: #F7943D;
$cl_Mid_Grey: #6C6E70;