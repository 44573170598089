.UC193 {
    .btn-quick-view{
        z-index: 1;
        border-radius: (30px * 0.5);
        background-color: rgba($cl_Black, .6);
        transition: opacity $animation-duration, background-color $animation-duration;
        transform: translate3d(-50%, 0, 0);
        font-size: 1.3rem;
        text-decoration: none;
        color: $cl_White;
        line-height: 30px;
        white-space: nowrap;
        @extend %u-sticker;
        @include u-sticker-rectangle(28px, 0 22px);
        @include u-sticker-position(auto auto 17px 50%);
        @media (min-width: $screen-md-min) {
            &:hover,
            &:focus{
                background-color: rgba($brand-primary, .8);
                text-decoration: none;
                color: $cl_White;
            }
        }
    }

    .Item {
        margin-bottom: 0;
    }
    > div {
        width: 100%;
        float: left;
    }
    .ShowOnlyInStock{
        margin-top: 0.5rem;
        float: right;
        label{
            font-weight: normal;
        }
    }

    .dropdown {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;

        button {
            &.btn {
                display: none;
            }
        }

        &-header {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .ItemsPerPageContainer {
                display: none;
            }

            .sortingTitle {
                margin-right: 10px;
            }
        }

        &-menu {
            display: block;
            left: inherit;
            float: none;
            position: relative;
            border: 0;
            background-color: transparent;
            box-shadow: none;
        }
    }

    .ProductListPagerContainer{
        float: none;
        clear: both;
        &.Top {
            float: left;
            .pagination{
                margin-top: 0;
            }
        }
    }
    .ItemsPerPageContainer{
        margin-top: 20px;
    }
    .ItemsPerPageOptions{
        padding-left: 0;
        list-style: none;
    }
}

.ProdItem{
    background-color: $cl_White;

    @if($btn-quick-view-visibility == default){
        .btn-quick-view {
            display: none;
        }
        @media (min-width: $screen-sm-min) {
            .btn-quick-view{
                display: inherit;
            }
            &:hover .btn-quick-view{
                opacity: 1;
            }
        }
    }
    @if($btn-quick-view-visibility == mobile){
        &:not(.DimensionProduct){
            .btn-quick-view {
                display: none;
            }
        }
        .btn-quick-view {
            @media (min-width: $screen-sm-min) {
                display: none;
            }
        }
    }
}
