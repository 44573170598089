.c-relimgtilesvariant{
    &__list{
        margin-left: -4px;
        margin-right: -4px;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
    }
    &__option{
        background-color: transparent;
        border: 1px solid $table-border-color;
        border-radius: $border-radius-base;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        padding: 0;
        margin: 4px;
        &:focus{
            outline: none;
        }
        &.disabled{
            opacity: 0.4;
        }
        &.selected {
            border-color: $brand-primary;
        }
    }
    &__image-container{
        position: relative;
        overflow: hidden;
        border-radius: $border-radius-base $border-radius-base 0 0;
    }
    &__unavailable-text{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        line-height: 1.6;
        background-color: #000;
        text-align: center;
        color: #fff;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}