.uc139 {
    max-width: 320px;
    margin: 0 auto;
    @extend %card-default-style;
    @extend %card-padding-base;
    .FormLabel {
        display: none;
    }
    .FormBox{
        margin-bottom: 10px;
    }
    .info { //common for several slides
        display: block;
        margin-bottom: 15px;
        line-height: 1.4;
    }
    &-login{
        &-controls {
            &__login-btn {
                width: 100%;
                margin-bottom: 20px;
                @extend %u-btn-primary;
                @extend %u-btn-size-large;
            }
            &__forgot-password-link{
                text-decoration: underline;
                font-weight: $label-font-weight;
            }
            &__register-link{
                float: right;
                text-decoration: underline;
                font-weight: $label-font-weight;
            }
        }
    }
    &-forgot{
        //slide: Forgot password
        &__send-password-btn{
            width: 100%;
            margin-bottom: 20px;
            @extend %u-btn-primary;
            @extend %u-btn-size-large;
        }
        &__back-btn{
            float: left;
            padding: 0;
            @extend %u-reset-btn-to-a-link;
            text-decoration: underline;
            font-weight: $label-font-weight;
        }
    }
    .ErrMsg {
        display: block;
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 0px;
        border: 1px solid $cl_Red;
        background-color: $cl_Wisp_Pink;
    }
}