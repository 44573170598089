$product-in-cart__icon:                        "%un-site-cart3-empty" !default; /* usage:   @extend $product-in-cart__icon; */
$product-in-cart__color:                       $gray-base !default;
$product-in-cart__font-size:                   18px !default;
// setup only top and left position to prevent animation artifacts on 'buy' event and leave 'auto' for bottom and right
$product-in-cart__position:                    calc(100% - 20px) auto auto calc(100% - 29px) !default; // top, right, bottom, left

$product-in-cart-count__size:                  16px !default;
$product-in-cart-count__font:                  9px 700 !default; // font-size, font-weight
// setup only top and left position to prevent animation artifacts on 'buy' event and leave 'auto' for bottom and right
$product-in-cart-count__position:              -10px auto auto 3px !default; // top, right, bottom, left
$product-in-cart-count__color:                 $cl_White !default;
$product-in-cart-count__background-color:      $brand-primary !default;

@import "e-product-in-cart";
.c-product-in-cart {

    @extend %u-product-in-cart;

    &__count {
        @extend %u-product-in-cart-count;
    }

    &__link {
        @extend %u-product-in-cart-link;
        @extend %u-product-in-cart-icon;

        // temporal solution
        // some controls has <i> inside the link
        // so emulate icon directly in the link for backward compatibility and hide existing icon
        i {
            display: none;
        }
    }
}

/* component markup

<div class="c-product-in-cart js-...">
    <a class="c-product-in-cart__link"></a>
    <span class="c-product-in-cart__count js-..."></span>
</div>

*/