%u-wish-list-btn-common {
    z-index: 1;
    border: 1px solid transparent;
    font-size: 0; // wish list button in product view control contains text inside, so set font-size to 0 to hide this text
    text-decoration: none;
    @extend %u-sticker;
    .fa {
        display: none;
    }
}

%u-wish-list-btn-left-bottom {
    @extend %u-wish-list-btn-common;
    @include u-sticker-circle($wish-list-btn__size);
    border: .1rem solid #fff;
    bottom: 0.8rem;
    left: 0.8rem;
    &::before {
        font-size: $wish-list-btn__font-size;
        width: $wish-list-btn__font-size;
        height: $wish-list-btn__font-size;
    }
}

%u-wish-list-btn {
    @extend %u-wish-list-btn-common;
    @include u-sticker-circle($wish-list-btn__size);
    @include u-sticker-position($wish-list-btn__position);
    &::before {
        font-size: $wish-list-btn__font-size;
        width: $wish-list-btn__font-size;
        height: $wish-list-btn__font-size;
    }
}

%u-wish-list-btn-lg {
    @extend %u-wish-list-btn-common;
    @include u-sticker-circle($wish-list-btn-lg__size);
    @include u-sticker-position($wish-list-btn-lg__position);
    &::before {
        font-size: $wish-list-btn-lg__font-size;
    }
}

%u-wish-list-add-btn {
    background-color: nth($wish-list-btn__background-color, 1);
    color: nth($wish-list-btn__color, 1);
    &:hover,
    &:focus {
        background-color: nth($wish-list-btn__background-color, 2);
        color: nth($wish-list-btn__color, 2);
    }
    &::before {
        @extend #{$wish-list-btn__icon-add};
    }
}

%u-wish-list-remove-btn {
    background-color: nth($wish-list-btn__background-color, 3);
    color: nth($wish-list-btn__color, 3);
    &:hover,
    &:focus {
        background-color: nth($wish-list-btn__background-color, 4);
        color: nth($wish-list-btn__color, 4);
    }
    &::before {
        @extend #{$wish-list-btn__icon-remove};
    }
}