/*!
 * UMW Config Shop v9.0.0
 */

@import "site/components/profile-menu/__dropdown.scss";

@import "global/components/flags/flags-system";
@import "global/components/wish-list-btn/wish-list-btn";
@import "global/components/c-product-in-cart/c-product-in-cart";
@import "global/components/price";
@import "global/components/c-rating";
@import "global/components/c-price";
@import "global/components/c-add-to-cart";
@import "global/components/c-quantity-plus-minus";

@import "global/overrides/klarna";

@import "controls/uc188-image-list/banner";
@import "controls/uc177-menu/uc-tags-menu";
@import "controls/uc193-product-list-v4/uc193";
@import "controls/uc176-value-check/uc176"; //Delivery value check
@import "controls/uc178-resellers/uc178";
@import "controls/uc194-comments/uc194";
@import "controls/uc195-product-list-filter/uc195"; //Product filter overrides
@import "controls/uc205-reviews/uc205-default";
@import "controls/uc209-product-view/c-variant-selector";
@import "controls/uc209-product-view/c-relimgtilesvariant";

@import "modules/bonus-system/bonus-system";
@import "modules/departments/umw-department";
@import "modules/my-page/my-page";
@import "modules/checkout/checkout";