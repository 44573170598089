%u-quantity-drp-small{
    width: 50px;
    height: $u-buy-btn-height-base;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    text-align: center;
    border-radius: $btn-border-radius-base;
}
%u-quantity-txb-small{
    padding: 0;
    text-align: center;
    border-radius: $btn-border-radius-base;
    width: $u-buy-btn-height-base;
    height: $u-buy-btn-height-base;
}
%u-quantity-txb-base{
    padding: 0;
    text-align: center;
}
%u-quantity-drp-large{
    width: 60px;
    height: $u-buy-btn-height-large;
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;
    border-radius: $btn-border-radius-large;
}
%u-quantity-txb-large{
    padding: 0;
    text-align: center;
    border-radius: $btn-border-radius-large;
    width: $u-buy-btn-height-large;
    height: $u-buy-btn-height-large;
}