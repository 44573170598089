.c-tabs{
    background-color: $cl_White;
    padding: 13px 24px 23px;

    $_c-tabs-alt-color: $nav-tabs-border-color;

    @extend %card-default-style;
    .nav-tabs{
        display: flex;
        padding: 0;
        margin-bottom: 15px;
        flex-direction: column;
        @media (min-width: $screen-sm-min) {
            flex-direction: row;
        }
        a{
            color: $cl_Chicago9;
            font-weight: $label-font-weight;
            text-transform: uppercase;
            display: block;
            line-height: 1em;
            padding-bottom: 1.6em;
            &:hover{
                text-decoration: none;
            }
            @extend %u-focus-outline-bordered;
        }
        li{
            list-style: none;
            position: relative;
            flex-grow: 1;
            flex-shrink: 1;
            &:not(:first-child){
                @media (min-width: $screen-sm-min) {
                    margin-left: 30px;
                }
            }
            &::after{
                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 4px;
                background-color: $_c-tabs-alt-color;
                border-radius: $btn-border-radius-base;
                transition: background-color $animation-duration;
            }
            @media (min-width: $screen-sm-min) {
                &:hover {
                    &::after {
                        background-color: $brand-primary;
                    }
                }
            }
        }
        li.active {
            &::after{
                background-color: $brand-primary;
            }
        }
        > li > a { //override .nav>li>a
            padding-top: 18px;
            padding-bottom: 18px;
            @media (min-width: $screen-sm-min) {
                padding-top: 10px;
            }
        }
    }
    .tab-content{
        iframe{
            max-width: 100%; //prevent width break for youtube frames or any another frames
        }
    }
    //Override for embedded control.
    .uc200__source{
        box-shadow: none;
        &-body{
            padding: 0;
        }
    }
    ul{ // increase specificity, used at:
        //.product-view-tabs,
        //.quick-view-product-tabs
        &.nav-tabs{
            border-bottom: 0;
            > li {
                > a {
                    padding-left: 0;
                    padding-right: 0;
                }
                &.active{
                    > a {
                        color: $text-color;
                    }
                }
            }
        }
        &.nav-tabs>li>a, &.nav-tabs>li>a:focus, &.nav-tabs>li>a:hover{
            border: none;
            background: none;
        }
    }

    //Controls integration
    .uc200-default-wcag__main-title,
    .uc200__main-title{ //used at UC209
        display: none;
    }
}