%flex {
    display: flex;
}

%flex-wrap {
    flex-wrap: wrap;
}

%flex-column {
    flex-direction: column;
}

%flex-column-sm {
    flex-direction: column;

    @media (min-width: $screen-sm-min) {
        flex-direction: row;
    }
}

%flex-column-md {
    flex-direction: column;

    @media (min-width: $screen-md-min) {
        flex-direction: row;
    }
}

%flex-column-lg {
    flex-direction: column;

    @media (min-width: $screen-lg-min) {
        flex-direction: row;
    }
}

%flex-reverse {
    flex-direction: row-reverse;
}

%flex-item-center {
    align-items: center;
}

%flex-item-top {
    align-items: flex-start;
}

%flex-item-bottom {
    align-items: flex-end;
}

%flex-item-center-start-sm {
    align-items: center;

    @media (min-width: $screen-sm-min) {
        align-items: flex-start;
    }
}

%flex-item-end-start-sm {
    align-items: flex-end;

    @media (min-width: $screen-sm-min) {
        align-items: center;
    }
}

%flex-item-right-left-sm {
    align-items: flex-start;

    @media (min-width: $screen-sm-min) {
        align-items: flex-end;
    }
}

%flex-item-baseline {
    align-items: baseline;
}

%flex-item-right {
    align-items: flex-end;
}

%flex-between {
    justify-content: space-between;
}

%flex-center {
    justify-content: center;
}

%flex-left {
    justify-content: left;
}

%flex-center-sm {
    justify-content: center;

    @media (min-width: $screen-sm-min) {
        justify-content: left;
    }
}

%flex-right {
    justify-content: flex-end;
}

%flex-directions {
    flex-direction: column;

    @media (min-width: $screen-lg-min) {
        flex-direction: row;
    }
}

%flex-md-row {
    flex-direction: column-reverse;

    @media (min-width: $screen-md-min) {
        flex-direction: column;
    }
}

%flex-self-stretch {
    align-self: stretch;
}

%flex-self-top {
    align-self: flex-start;
}