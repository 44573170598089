$site-header-desktop__bg: $cl_White !default;

$site-header-desktop__top-line-background: $brand-primary-alternative !default;
$site-header-desktop__top-line-color: $cl_White !default;
$site-header-desktop__top-line-icon-color: $brand-primary !default;
$site-header-desktop__top-line-height: 24px !default;

$site-header-desktop__middle-line-height: 112px !default;
$site-header-desktop__middle-line-stuck-height: 78px !default;

$site-header-desktop__bottom-line-background: $brand-primary !default;
$site-header-desktop__bottom-line-height: $PM-line-height !default;

$site-header-desktop__btn-font-size: 30px !default;
$site-header-desktop__btn-color: $brand-primary-alternative !default;
$site-header-desktop__top-line-font-weight: $label-font-weight !default;

%site-header-desktop-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background: none;
    font-size: $site-header-desktop__btn-font-size;
    text-align: center;
    color: $site-header-desktop__btn-color;
    &:focus {
        outline: 0;
    }
}


.site-header {

    $site-header: &;

    &-desktop {

        background-color: $site-header-desktop__bg;

        /* lines */
        &__top-line {
            position: relative;
            z-index: 3; // why-buys blockquote should be upper the other elements in the header
            margin-top: 0; // initial value for animation
            background-color: $site-header-desktop__top-line-background;
            font-weight: $site-header-desktop__top-line-font-weight;
            &-inner-container {
                height: $site-header-desktop__top-line-height;
            }
        }
        #{$site-header}_is-stuck &__top-line,
        #{$site-header}_appearing &__top-line,
        #{$site-header}_disappearing &__top-line {
            display: none;
        }

        &__middle-line {
            position: relative;
            z-index: 2; // profile quick menu + site cart popup should be upper the other elements in the header
            &-inner-container {
                height: $site-header-desktop__middle-line_height;
            }
        }
        #{$site-header}_is-stuck &__middle-line-inner-container,
        #{$site-header}_appearing &__middle-line-inner-container,
        #{$site-header}_disappearing &__middle-line-inner-container {
            height: $site-header-desktop__middle-line-stuck_height;
        }

        &__bottom-line {
            position: relative;
            z-index: 1; // should have the smallest z-index
            background-color: $site-header-desktop__bottom-line-background;
        }


        /* middle parts */
        &__left-pane,
        &__right-pane {
            flex: 0 0 auto;
        }

        &__left-pane {
        }

        &__middle-pane {
            // we need some space between blocks
            margin-left: 2rem;
            margin-right: 2rem;
        }

        &__right-pane {
        }


        /* elements */
        &__why-buy {
            li {
                color: $site-header-desktop__top-line-color;
                &::before {
                    color: $site-header-desktop__top-line-icon-color;
                }
            }
        }

        &__phone {
            color: $site-header-desktop__top-line-color;
            &:hover,
            &:focus {
                color: $site-header-desktop__top-line-color;
            }
            &::before {
                color: $site-header-desktop__top-line-icon-color;
            }
        }

        &__logo {
        }

    }

}