/*REFERRALS*/
.refferal {
    &__controls {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin: 0 -10px -20px; /* negative bottom offset to compensate margin-bottom of refferal-group and refferal-name containers */
    }
    &-group {
    }
    &-group,
    &-name {
        flex-grow: 1;
        padding: 0 10px;
        margin-bottom: 20px;
        min-width: 180px; // same width as delivery options
        @media(min-width: $screen-sm-min) {
            max-width: 50%;
        }

        &__label {
            font-weight: 400;
        }
    }
}