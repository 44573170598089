$site-phone-number-fs:           13px !default;
$site-phone-number-space:        8px !default;
$site-phone-number-color:        $cl_White !default;
$site-phone-icon-color:          $brand-primary !default;

.site-phone {

    display: flex;
    align-items: center;
    font-size: $site-phone-number-fs;
    color: $site-phone-number-color;

    &:focus,
    &:hover{
        text-decoration: none;
    }

    &:before {
        @extend %fa-phone;
        margin-right: $site-phone-number-space;
        color: $site-phone-icon-color;
    }
}
