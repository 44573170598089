@mixin uni-button-variant($color, $background, $border) {
    color: $color;
    background-color: $background;
    border-color: $border;

    &:focus {
        color: $color;
        background-color: darken($background, 10%);
        border-color: darken($border, 25%);
    }
    &:hover {
        color: $color;
        background-color: darken($background, 10%);
        border-color: darken($border, 12%);
    }
    &:active {
        color: $color;
        background-color: darken($background, 10%);
        border-color: darken($border, 12%);

        &:hover,
        &:focus {
            color: $color;
            background-color: darken($background, 17%);
            border-color: darken($border, 25%);
        }
    }
    &[disabled] {
        &:hover,
        &:focus {
            background-color: $background;
            border-color: $border;
        }
    }
}

@mixin uni-button-advanced-hover($color, $background, $border, $color-hover, $background-hover, $border-hover) {
    color: $color;
    background-color: $background;
    border-color: $border;

    &:hover,
    &:focus,
    &:active{
        color: $color-hover;
        border-color: $border-hover;
        background-color: $background-hover;
    }
    &:active {
        &:focus,
        &:active{
            color: $color-hover;
            border-color: $border-hover;
            background-color: $background-hover;
        }
    }
    &[disabled] {
        &:hover,
        &:focus {
            color: $color;
            background-color: $background;
            border-color: $border;
        }
    }
}