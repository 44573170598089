.c-variant-selector{
    &__term-description{
        &_tiles{
            display: flex;
            flex-wrap: wrap;
        }
    }
    &__label{
        margin-bottom: 3px;
    }
    &__variant-tile{
        border: 1px solid $table-border-color;
        background-color: $cl_White;
        padding: .2em .6em;
        margin: 2px;
        border-radius: $border-radius-base;

        &.disabled{
            cursor: not-allowed;
            opacity: .65;
        }
        &.selected{
            border-color: $brand-primary;
            background-color: $brand-primary;
            @include u-set-adaptive-color($brand-primary, $light-text-color: $cl_White, $dark-text-color: $text-color, $threshold: 50%);
        }
    }
}