.uc140 {
    .FormLabel,
    .RequiredField,
    .FormBox{
        display: block;
        float: left;
        @media (max-width: $screen-xs-max) {
            width: auto;
        }
    }

    input[type="checkbox"] + label{ // deliver same address, could be more places
        margin-left: 5px;
        display: inline-block;
        vertical-align: middle;
    }
    span.Success {
        color: $brand-success;
    }

    &-checkout{
        //Main action buttons: prev/next
        &__buttons {
            clear: both;
            //margin: 0 0 25px;
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-end;
            padding-top: 24px;
            border-top: 1px solid $table-border-color;
            &_step-1 {
                //Yes, it's flex and has proper justify content parameter, but text align is used to avoid specificity increase because of inline style="display:block;"
                text-align: right;
            }
            &_step-3{
                &_top{
                    display: none;
                    + .TermsContainer{
                        display: none;
                    }
                }
                &_bottom{
                    margin-top: 25px;
                }
            }
        }
        &__back-btn{
            @extend %u-reset-btn-to-a-link;
            text-decoration: underline;
        }
        &__next-btn{
            @extend %u-btn-primary;
            @extend %u-btn-size-large;
        }
        //# Main action buttons: prev/next
    }

    .Login,
    .Payment,
    .Delivery,
    .Resellers {
        margin-bottom: 3rem;
    }
    .Title {
        margin-top: 0;
        margin-bottom: 1em;
    }

    /*STEP 1 - Login or register */
    &-login{
        @extend %card-default-style;
        @extend %card-padding-base;
        @media (min-width: $screen-md-min) {
            width: 38%;
            margin-right: 2%;
            float: left;
        }
        &__username{
            margin-bottom: 8px;
        }
        &-controls{
            margin-top: 24px;
            &__login-btn{
                width: 100%;
                @extend %u-btn-primary;
                @extend %u-btn-size-large;
                margin-bottom: 20px;
            }
            &__forgot-password-link{
                text-decoration: underline;
            }
        }
        .FormLabel {
            display: none;
        }
        .FormBox{
            height: 40px;
            width: 100% !important;
        }
        .ErrMsg:after {
            display: block;
            content: " ";
            height: 10px;
        }
        .RequiredField {
            display: none;
        }
    }

    /*Registration box*/
    .RegType {
        @extend %registration-type;
        margin-bottom: 20px;
    }
    .Address {
        @extend %card-default-style;
        @extend %card-padding-base;
        @media (min-width: $screen-md-min) {
            width: 60%;
            float: left;
        }
        > div {
            &:not(:first-child){
                padding-top: 25px;
                padding-left: $u-card-padding-horizontal;
                padding-right: $u-card-padding-horizontal;
                margin-left: -1 * $u-card-padding-horizontal;
                margin-right: -1 * $u-card-padding-horizontal;
                margin-top: 25px;
                border-top: 1px solid $table-border-color;
            }
        }
        .ErrInp {
            /*from system JS*/
            .FormBox {
                border: 2px solid $state-danger-border;
                background-color: $state-danger-bg;
                background-image: url("../img/checkout/chevron-left_l.png");
                background-repeat: no-repeat;
                background-position: right 5px bottom 50%;
            }
        }
        .FormMultiBox {
            width: 200px;
            min-height: 140px;
        }
        .FormLabel {
            min-width: 138px;
            line-height: 30px;
        }
        .RequiredField {
            width: 15px;
            margin-left: -15px;
            text-align: left;
        }
        .DeliveryDate {
            img {
                cursor: pointer;
            }
        }
        .Success,
        .ErrMsg {
            /*Login or register error message Chrome fix*/
            display: block;
            margin: 8px 0 0 10px;
            float: left;
            white-space: nowrap;
        }
        .Tooltip {
            margin-top: 8px;
            margin-left: 10px;
        }

        @media (min-width: $screen-sm-min) {
            .UC140imgDeliveryDate{
                margin-left: 10px;
            }
        }
        @media (max-width: $screen-xs-max) {
            span[id*="reqVatxb"] {
                /*linked with JS*/
                display: none !important;
            }
            .FormLabel {
                min-width: inherit;
                padding-bottom: 4px;
                line-height: inherit;
            }
            .RequiredField {
                margin: 0;
            }
            .FormBox {
                width: 100% !important;
                float: none;
                clear: both;
            }
            .FormMultiBox {
                width: 100%;
            }
            .DeliveryDate {
                img {
                    margin-top: 5px;
                    margin-left: 5px;
                }
            }
            .Success,
            .ErrMsg {
                /*Login or register error message Chrome fix*/
                margin: 3px 0 0 0;
                white-space: inherit;
            }
        }
    }
    .NewPassword{
        position: relative;
        .c-help-icon{
            position: absolute;
            top: 0;
            right: 0;
            @media (min-width: $screen-sm-min) {
                margin-left: 4px;
                margin-top: 6px;
                position: static;
            }
        }
    }
    .InvoiceAddress {
        > div:not(:first-of-type),
        .Company { // there is #CompanyDetails block when Registration type selector is visible, put space for .Company which inside this block
            margin-top: 10px;
        }
    }
    /*_Registration box*/

     /*STEP 2 - Payment Delivery options */
    .PaymentOptions,
    .DeliveryOptions {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        @media (min-width: $screen-sm-min) {
            flex-direction: row;
        }
    }
    /* Payment & Delivery option boxes */
    .PaymentOption > input[type="radio"],
    .DeliveryOption > input[type="radio"] {
        display: none;
    }
    .PaymentOption > input[type="radio"]:checked + label,
    .DeliveryOption > input[type="radio"]:checked + label {
        opacity: 1;
        box-shadow: 0 0 0 1px $brand-primary;
        color: $link-color;
    }
    .DeliveryOption > input[type="radio"]:checked + label ~ .DelPayPrice{
        color: $link-color;
    }
    .PaymentOption.Unsupported {
        .ErrMsg {
            padding: 0 20px;
            vertical-align: middle;
            position: absolute;
            bottom: 30px;
            left: 0;
            font-size: 14px;
            text-align: center;

            @media (max-width: $screen-xs-max) {
                bottom: 20px;
            }
        }
        label {
            @include hide-text;
        }
    }
    .PaymentOption,
    .DeliveryOption {
        position: relative;
        display: flex;
        & > .HelpText {
            display: block;
            padding-left: 18px;
            margin-bottom: 7px;
            clear: both;
            font-size: 13px;
        }
        @media (max-width: $screen-xs-max) {
            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }
        @media (min-width: $screen-sm-min) {
            width: 174px;
            margin-bottom: $grid-gutter-width * 0.5;
            margin-right: $grid-gutter-width * 0.5;
        }
    }
    /*Common styles*/
    .PaymentDelivery {
        .DeliveryDate {
            z-index: 9;
            max-width: 620px;
            padding: 6px 10px;
            margin-bottom: 20px;
            overflow: hidden;
            position: relative;
            @extend %card-default-style;
            @extend %card-padding-base;
            .FormLabel,
            .RequiredField,
            .FormBox,
            span {
                float: none !important;
                display: inline-block;
                vertical-align: middle;
            }
            .RequiredField{
                margin-right: 0.3em;
            }
        }
        .DeliveryDateBtn{
            @extend %u-btn;
            @extend %u-btn-primary;
            width: 78px;
            margin-left: 8px;
            cursor: pointer;
            img{
                display: none;
            }
            &::before {
                @extend %fa-calendar;
            }
        }
        .DeliveryDateInner{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        .DeliveryTimeContainer{
            select{
                width: auto;
            }
        }
        .DeliveryDateContainer,
        .DeliveryTimeContainer{
            margin: 4px;
            display: flex;
            align-items: center;
            label{
                margin-bottom: 0;
                margin-right: 8px;
            }
        }
        .Bonus{
            border-top: 1px solid $table-border-color;
            padding-top: $grid-size * 3;
            padding-bottom: $grid-size * 4;
        }
        .Tooltip { /* .PaymentTooltip + .DeliveryTooltip*/
            position: absolute;
            top: 5px;
            right: 5px;
            z-index: 1;
        }
        label.FormRbl {
            line-height: 1em;
            width: 100%;
            min-height: 74px;
            cursor: pointer;
            position: relative;
            z-index: 1;
            box-shadow: 0 0 5px rgba($cl_Black, .15);
            background-color: $cl_White;
            padding: 10px 10px 10px 110px;
            margin-bottom: 0; // reset bootstrap styles
            display: flex;
            flex-direction: column;
            justify-content: center;
            @media (min-width: $screen-sm-min) {
                justify-content: flex-start;
                min-height: inherit;
                padding: 20px 10px 20px;
                text-align: center;
            }
            &.unavailable{ // specific user credentials are required to see this state
                opacity: 0.7;
                background-color: $gray-lighter;
                cursor: not-allowed;
            }
            img {
                // Image positioned absolute because bad handling of pointer-ventes-none at Windows8.1 IE11 minor versions
                pointer-events:none;
                display: block;
                max-width: 80px;
                max-height: 54px;
                position: absolute;
                top: 10px;
                left: 10px;
                @media (min-width: $screen-sm-min) {
                    position: static;
                    //left: 50%;
                    //transform: translateX(-50%);
                    margin: 0 auto 10px;
                }
            }
            span.Description {
                display: block;
                font-size: 11px;
            }
            .KlarnaTermsLink {
                display: block;
                margin-top: 10px;
                @media (min-width: $screen-sm-min) {
                    text-align: center;
                }
            }
            .klarna-link {
                color: $brand-danger;
            }
        }
        .DelPayPrice {
            font-size: 12px;
            width: 100%;
            position: absolute;
            bottom: 11px;
            left: 0;
            z-index: 1;
            padding-left: 110px;
            @media (min-width: $screen-sm-min) {
                bottom: 2px;
                padding-left: 0;
                text-align: center;
            }
        }
    }
    .DeliveryOption {
        label.FormRbl {
            padding-bottom: 24px !important; //space for price label placed absolutely
        }
    }
    .RegNewsletter{
        .checkbox{
            margin-bottom: 0;
        }
    }
    .UseSameAddress {
        label{
            font-weight: normal;
        }
        .FormChkBox{
            display: flex;
        }
    }
    .DeliveryAddressFields{ //Multiple delivery addresses is enabled
        padding-top: 10px;
        > div {
            margin-bottom: 10px;
        }
    }

    .NewAddressLink {
        position: relative;
        padding-left: 1.6rem;
        display: inline-block;
        margin-top: .8rem;
        cursor: pointer;

        &::before,
        &::after {
            position: absolute;
            content: '';
            background-color: $cl_Black;
        } 
        
        &::before {
            width: .2rem;
            height: .8rem;
            left: .3rem;
            top: calc(50% - 4px);
        }

        &::after {
            height: .2rem;
            width: .8rem;
            left: 0;
            top: calc(50% - 1px);
        }
    }
    
    > .ErrMsg {
        display: block;
        padding: 15px;
        margin-bottom: 10px;
        border: 1px solid $alert-danger-border;
        background-color: $alert-danger-bg;
        line-height: 16px;
        font-size: 14px;
        color: $alert-danger-text;
        text-align: center;

        @media (max-width: $screen-xs-max) {
            margin: 0 auto;
        }
    }
    /*STEP 3 - Confirm*/
    .Confirm {
        .TermsContainer {
            margin-top: 12px;
            text-align: right;
            a{
                text-decoration: underline;
            }
        }
        .CartGrid {
            margin-bottom: 0;
        }
        // apply styles only on main title container
        // there are also .TitleContainer in children boxes which shouldnt have such margins
        > .TitleContainer {
            margin-bottom: 30px;
        }
        /*First row with addesses*/
        .ConfirmAddress,
        .ConfirmDeliveryAddress,
        .ConfirmPaymentDelivery {
            background-color: $cl_White;
            border: 1px solid $cl_Gray_Nurse2;
            margin-bottom: 20px;
            @media (min-width: $screen-sm-min) {
                float: left;
                width: 32%;
            }
            .TitleContainer {
                margin-bottom: 0;
                font-size: $font-size-base + 2px;
            }
            .Title{
                margin-bottom: 0;
            }
            & > div{
                padding: 5px 10px;
                @media (min-width: $screen-sm-min) {
                    padding: 11px 16px;
                }
                &:not(:first-child){
                    border-top: 1px solid $table-border-color;
                }
            }
            .FormLabel {
                &:first-child {
                    font-weight: $label-font-weight;
                    width: 120px;
                }
                @media (max-width: $screen-xs-max) {
                    padding-right: 10px;
                    &:first-child {
                        width: auto;
                    }
                }
            }
        }
        .ConfirmDeliveryAddress,
        .ConfirmPaymentDelivery {
            @media (min-width: $screen-sm-min) {
                margin-left: 2%;
            }
        }
        .ConfirmPaymentDelivery{
            .FormLabel{
                width: auto;
                margin-right: 10px;
            }
        }
        /*Details table*/
        .ConfirmOrderDetails {
            width: 100%;
            margin: 30px 0;
            float: left;
            clear: both;
            table {
                @media (max-width: $screen-xs-max) {
                    display: none;
                }
            }
        }
        .OrderSummary {
            width: 100%;
            float: left;
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            * {
                line-height: 22px;
            }
            span{
                &:first-child{
                    font-weight: $label-font-weight;
                }
                &:not(:first-child){
                    margin-left: 5px;
                }
            }
            .TotalPrice{
                .notranslate{
                    font-weight: $label-font-weight;
                    font-size: 16px;
                }
            }
        }
    }

    &-delivery-reseller {
        &__tr {
            cursor: pointer;
        }
        &__td {
            &_radio {
                width: 50px;
                text-align: center;
            }
        }
    }

}