.UC141 {
    .DescriptionContainer {
         @include article-content;
         @include article-formating;
    }

    img{
        @media (max-width: $screen-xs-max){
            width: 100%;
            height: auto;
        }
    }

    @media (min-width: $screen-sm-min) {
        .Content {
            display: flex;
            flex-direction: column;

            .ImageContainer {
                padding-right: 2rem;
            }
        }
    }
}
