.site-underheader {

    position: relative;
    z-index: 1; // z-index less then .site-header
    background-color: $site-header-mobile__bg;

    // this block is always visible
    // it uses to compensate height of the header
    &__compensator {
        @media (max-width: $screen-sm-max) {
            height: $site-header-mobile__height;
        }
        @media (min-width: $screen-md-min) {
            height: $site-header-desktop__top-line-height + $site-header-desktop__middle-line-height + $site-header-desktop__bottom-line-height;
        }
    }

    // this is collapsable block
    // it contains mobile menu and mobile search controls
    // it opens when on menu/search button click
    &__container {
        @extend %site-header-shadow;
        & > .container-fluid{
            padding-bottom: 14px;
        }
    }

}