@mixin make-table-mobile (){
    thead{
        display: none;
    }
    tbody{
        display: flex;
        flex-direction: column;

        & > tr{
            border: 1px solid $table-border-color;
            &:not(:first-child){
                margin-top: 20px;
            }

            & > td {
                display: flex;
                flex-direction: column;
                &:first-child{
                    border-top: none;
                }
                &[data-th]{
                    &::before{
                        font-weight: $label-font-weight;
                        display: block;
                        content: attr(data-th);
                    }
                }
            }
        }
    }
}