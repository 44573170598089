$addthis__size:                  40px !default;
$addthis__width:                 $addthis__size !default;
$addthis__height:                $addthis__size !default;
$addthis__space:                 8px !default;
$addthis__font-size:             20px !default;

$addthis__border-radius:         0 !default;
$addthis__bg:                    transparent !default;
$addthis__color:                 $cl_White !default;
$addthis__border-width:          1px !default;
$addthis__border-style:          solid !default; //solid, dashed, dotted
$addthis__border-color:          $cl_Chicago3 !default;

$addthis__bg-hover:              $brand-primary !default;
$addthis__color-hover:           $cl_White !default;
$addthis__border-color-hover:    $brand-primary !default;

/*SOCIAL Buttons*/
#_atssh {
    display: none;
}

div.addthis_toolbox {
    &.custom {
        @extend %flex;
        @extend %flex-center;
        @extend %flex-wrap;
        @extend %flex-item-center;
        font-size: $addthis__font-size;

        a {
            @extend %flex;
            @extend %flex-center;
            @extend %flex-item-center;
            border: $addthis__border-width $addthis__border-style $addthis__border-color;
            background-color: $addthis__bg;
            color: $addthis__color;
            width: $addthis__width;
            height: $addthis__height;
            position: relative;
            text-decoration: none;
            cursor: pointer;
            margin-bottom: 0;
            border-radius: $addthis__border-radius;

            i {
                @extend %flex;
            }

            &:hover,
            &:focus {
                border-color: $addthis__border-color-hover;
                background-color: $addthis__bg-hover;
                color: $addthis__color-hover;
                text-decoration: none;
            }

            &:not(:first-child) {
                margin-left: $addthis__space;
            }
        }
    }

    &_left {
        &.custom {
            justify-content: center;

            @media (min-width: $screen-sm-min) {
                justify-content: flex-start;
            }
        }
    }

    &_right {
        &.custom {
            justify-content: center;

            @media (min-width: $screen-sm-min) {
                justify-content: flex-end;
            }
        }
    }
}
