//Modifier descriptions
//_layout-1 - only factor is shown as drop down (predefined allowed quantities) with buy button right to it
//_layout-2 - drop down (predefined allowed quantities) with buy button right to it
//_layout-3 - factor and fraction are shown, button below input boxes
//_layout-4 - simple quantity input with buy button right to it

.c-add-to-cart{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &-quantity-input {
        -moz-appearance: textfield;
        -webkit-appearance: textfield;
        -ms-appearance: textfield;
    }

    &-quantity-input,
    &-quantity-drop-down{
        flex: 0 0 auto;
        margin-right: $u-quantity-margin-right;
    }
    &-quantity-drop-down {
        width: 50px;
    }
    &-factor-input,
    &-fraction-input{
        @extend %u-quantity-txb-base;
    }
    &-btn{
        flex: 1 1 auto;
        @extend %u-btn-icon-animation;
        &::before{
            @extend #{$product-in-cart__icon};
            font-size: 1.4em;
        }
        &.is-bought{
            &::before {
                @extend %fa-check;
                display: inline-block;
            }
        }
        //&__label{}
    }

    &_layout-1{
        align-items: flex-end;
    }
    &_layout-2{
        flex-wrap: nowrap;
    }
    &_layout-3{
        .c-add-to-cart-factor,
        .c-add-to-cart-fraction{
            flex: 1 1 0;
        }
        .c-add-to-cart-factor{
            margin-right: 0.5em;
        }
        .c-add-to-cart-fraction{
            margin-left: 0.5em;
        }
        .c-add-to-cart-btn{
            width: 100%;
            margin-top: 1em;
        }
    }
}