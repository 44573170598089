/* Page Layout - 3nd generation */
.l-pb{
    $_this: &;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;

    &-aside{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    &-content {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &_v1{
        @if variable-exists(l-aside-width-sm-v1) {
            @media (min-width: $screen-sm-min) {
                flex-direction: row;
                #{$_this}-aside {
                    width: $l-aside-width-sm-v1;
                }
                #{$_this}-content{
                    width: calc(100% - #{$l-aside-width-sm-v1} - #{$l-aside-space-sm-v1});
                }
            }
        }
        @if variable-exists(l-aside-width-md-v1) {
            @media (min-width: $screen-md-min) {
                flex-direction: row;
                #{$_this}-aside {
                    width: $l-aside-width-md-v1;
                }
                #{$_this}-content{
                    width: calc(100% - #{$l-aside-width-md-v1} - #{$l-aside-space-md-v1});
                }
            }
        }
        @if variable-exists(l-aside-width-lg-v1) {
            @media (min-width: $screen-lg-min) {
                flex-direction: row;
                #{$_this}-aside {
                    width: $l-aside-width-lg-v1;
                }
                #{$_this}-content{
                    width: calc(100% - #{$l-aside-width-lg-v1} - #{$l-aside-space-lg-v1});
                }
            }
        }
    }
}