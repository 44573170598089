%u-product-in-cart {
    z-index: 2;
    padding: 0; // dont remember why I put it here :( Alex U.   do we need this?
    @extend %u-sticker;
    @include u-sticker-position($product-in-cart__position);
}

%u-product-in-cart-link {
    position: relative;
    z-index: 1; // local z-index, to be under in-cart 'count' block
    text-decoration: none;
    color: $product-in-cart__color;
    @extend %flex;
    &:hover,
    &:focus {
        text-decoration: none;
        color: $product-in-cart__color;
    }
}

%u-product-in-cart-icon {
    &::before {
        font-size: $product-in-cart__font-size;
        @extend #{$product-in-cart__icon};
    }
}

%u-product-in-cart-count {
    z-index: 2; // local z-index, to be adobe in-cart 'link' block
    min-width: $product-in-cart-count__size;
    height: $product-in-cart-count__size;
    padding: 0 2px; // to have small paddings for big amount of products
    border-radius: round($product-in-cart-count__size * 0.5);
    background-color: $product-in-cart-count__background-color;
    pointer-events: none;
    letter-spacing: -.035em;
    color: $product-in-cart-count__color;
    @extend %u-sticker;
    @include font-properties($product-in-cart-count__font);
    @include u-sticker-position($product-in-cart-count__position);
}