/*!
 * UMW Config Common v9.1.0
 */

@import "variables";

/*mixins with params */
@import "global/mixins/color";
@import "modules/my-page/registration-type";
@import "global/mixins/slick-controls";
@import "global/mixins/sticker";
@import "global/mixins/z-index";
@import "global/mixins/scrollbar";

/* extends*/
@import "global/extends/u-form-control";
@import "global/extends/clearfix";
@import "global/extends/u-standalone-cart-icon";
@import "global/extends/u-list-unstyled";
@import "global/extends/u-outline";
@import "global/extends/sticker";
@import "global/extends/font-awesome";

@import "global/components/buttons/buttons-system";
@import "global/components/tables/table";
@import "global/components/typograpty/type";
@import "global/components/c-product-in-cart/c-product-in-cart";
@import "global/components/input/e-inputs";
@import "global/components/u-card/u-card";
@import "global/components/pagination/paginator"; // old-fashion paginator
// @import "global/components/pagination"; // bootrstrap-style markup for new .uc-document-list
@import "global/components/input/inputs";
@import "global/components/alerts";
@import "global/components/tabs/c-tabs-3";
@import "global/components/slider-menu"; /*.SliderMenu extension for UC177, UC1110, UC195*/

@import "global/overrides/bootstrap";
@import "global/overrides/jquery-ui";
@import "global/overrides/browser";
@import "global/overrides/print";

@import "layout/layout-system";

/*----------  COMMON PARTS OF CONTROLS ----------*/
@import "controls/uc188-image-list/uc-image-list-v2";
@import "controls/uc129-feedback/uc129_default";

/*------  CONTROLS  ------*/
@import "controls/uc141-view-page/uc141";
@import "controls/uc208-unsubscribe-from-newsletters/uc208-unsubscribe";
@import "controls/uc170-search-results-templated/uc170";
@import "controls/uc171-view-document-templated/uc171";
@import "controls/uc177-menu/uc177_v2";
@import "controls/uc179-document-list-menu/uc179";
@import "controls/uc200-file-list-v4/uc200-default";
@import "controls/search/common_searchbox";
@import "controls/uc204-smart-search/common_smart-search";
@import "controls/uc174-list-child-pages/uc174_1";
@import "controls/uc188-image-list/slick-slider/slick";

//Site related (mostly used at Default.aspx)
@import "site/components/payment/site-payment-methodes";
@import "site/components/why-buy/why-buy";
@import "site/components/phone/phone";
@import "site/components/breadcrumbs/breadcrumbs-v2";
@import "site/components/social/addthis";
@import "site/components/scroll-to-top/scroll-to-top";

/*=====  End of UNIMICRO LAYER  ======*/