%site-header-shadow {
    box-shadow: 0 2px 6px rgba($gray-base, .16);
}

@keyframes site-header-animation-appearing {
    from {
        transform: translate3d(0, -100%, 0);
    }
    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes site-header-animation-disappearing {
    from {
        transform: translate3d(0, 0, 0);
    }
    to {
        transform: translate3d(0, -100%, 0);
    }
}


.site-header {

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: z-index(site-header); // z-index bigger then .site-underheader
    transform: translate3d(0, 0, 0);
    @extend %site-header-shadow;

    &_is-stuck {
        position: fixed;
    }

    &_appearing {
        position: fixed;
        animation: site-header-animation-appearing;
        animation-duration: .5s;
    }

    &_disappearing {
        position: fixed;
        animation: site-header-animation-disappearing;
        animation-duration: .5s;
    }

}

@import "header-desktop";
@import "header-mobile";
@import "underheader";

@import "controls/products-menu-desktop";
@import "controls/products-menu-mobile";
@import "controls/site-menu-mobile";

@import "controls/profile-quick-menu-desktop";
@import "controls/profile-quick-menu-mobile";

@import "controls/site-cart-desktop";
@import "controls/site-cart-mobile";

@import "controls/smart-search-desktop";
@import "controls/smart-search-mobile";

@import "controls/site-search-mobile";