%u-heading-non-h-tag {
    line-height: $headings-line-height;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    color: $headings-color;
}

%u-heading {
    margin-top: 0;
    margin-bottom: $heading-h1-mb;
}

%u-heading-h1 {
    font-size: $font-size-h2;
    @extend %u-heading;

    @media (min-width: $screen-md-min) {
        font-size: $font-size-h1;
    }
}

%u-heading-h2 {
    font-size: $font-size-h3;
    @extend %u-heading;

    @media (min-width: $screen-md-min) {
        font-size: $font-size-h2;
    }
}

%u-heading-h3 {
    font-size: $font-size-h4;
    @extend %u-heading;

    @media (min-width: $screen-md-min) {
        font-size: $font-size-h3;
    }
}

%u-heading-h4 {
    font-size: $font-size-h4;
    @extend %u-heading;

}

%u-heading-h5 {
    font-size: $font-size-h5;
    @extend %u-heading;
}

%u-heading-h6 {
    font-size: $font-size-h6;
    @extend %u-heading;
}

%u-text-inherit {
    @include u-text-inherit;
}

%u-text-d-to-l{
    color: $text-color;
    &:hover {
        color: $text-color-gray;
    }
}
%u-text-l-to-d{
    color: $text-color-gray;
    &:hover {
        color: $text-color;
    }
}

%u-list-title-d-to-l{
    @extend %u-text-d-to-l;
    &:hover {
        text-decoration: none;
    }
}
%u-list-title-l-to-d{
    @extend %u-text-l-to-d;
}


///TODO: split '.text' class and extends. Is used at footer
%text-center-md {
    text-align: center;

    @media (min-width: $screen-md-min) {
        text-align: left;
    }
}
.text-center-md {
    @extend %text-center-md;
}